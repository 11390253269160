import React, { Component } from 'react'
import {
  Dialog, DialogContent, DialogTitle, Typography, Button, Avatar,
  ButtonGroup, CircularProgress
} from '@mui/material';
import EventBus from "eventing-bus";
import { ACTION_TYPES } from "src/constants";
import { deepOrange } from '@mui/material/colors'
import styles from './_delete-dialog.module.scss';
import { toast } from "react-toastify";

class DeleteDialog extends Component {

  constructor(props) {
    super(props)

    this.state = {
      isLoading: false
    }
  }

  componentDidMount() {
    this.createRecommendationSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_DELETE_LEAD,
      () => {
        this.setState({ isLoading: true });
      }
    );

    this.createRecommendationSuccessSubscription = EventBus.on(
      ACTION_TYPES.DELETE_LEAD_SUCCESS,
      () => {
        toast.success("Lead deleted", "success");
        this.setState({ isLoading: false }, () => this.props.onClose())
      }
    );

    this.createRecommendationFailureSubscription = EventBus.on(
      ACTION_TYPES.DELETE_LEAD_FAILURE,
      () => this.setState({ isLoading: false })
    );
  }

  componentWillUnmount() {
    this.createRecommendationSubscription()
    this.createRecommendationSuccessSubscription()
    this.createRecommendationFailureSubscription()
  }

  handleDeleteLead = () => {
    this.props.requestDeleteLead(this.props.lead.uuid);
  }

  render() {
    const { isOpen, onClose } = this.props;
    let { lead } = this.props
    lead ||= {}

    let { fullName, uuid } = lead
    fullName ||= ""; uuid ||= ""

    return (
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <Avatar sx={{ bgcolor: deepOrange[500] }}>{fullName[0]}</Avatar>
            <span style={{marginLeft: 8}}>Delete {fullName}</span>
          </div>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2">
            Are you sure you want to delete this lead? This action cannot be undone.
          </Typography>
          <ButtonGroup className={styles['btns']} style={{display: 'flex'}}>
            <Button
              variant='outlined'
              disabled={this.state.isLoading}
              startIcon={this.state.isLoading ? <CircularProgress color="inherit" /> : <div />}
              onClick={this.handleDeleteLead}
            >
              Delete Lead
            </Button>

            <Button
              variant='contained'
              disabled={this.state.isLoading}
              onClick={onClose}
            >
              Cancel
            </Button>
         </ButtonGroup>
       </DialogContent>
      </Dialog>
    );
  }
}

export default DeleteDialog