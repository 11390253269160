import { connect } from 'react-redux';
import DeleteDialog from './delete-dialog';
import {
  requestDeleteLead
} from "src/redux/actions";

const mapStateToProps = (state) =>  {
  return {
  }
}

export default connect(mapStateToProps, { requestDeleteLead })(DeleteDialog);