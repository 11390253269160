import React, { useMemo } from "react";
import moment from "moment";
import { Badge } from "src/components/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { DataTable, TextOne, LinkOne } from "../../../../../components";
import styles from "./_webhooks-table.module.scss";

const API_ENDPOINT_URL = process.env.API_ENDPOINT_URL || "https://surface.thesavvyapp.in";

const WebhooksTable = (props) => {
  const tableStyles = {
    bodyRow: {
      backgroundColor: "#ffffff",
      borderBottomColor: "#ffffff",
    },
    tableHeader: {
      backgroundColor: "#ffffff",
      borderTop: "1px solid #F3F3F3",
      borderBottom: "unset",
    },
  };

  const { totalItems, data, openCreateEditModal } = props;

  const pageSize = 50;

  const paginationConfig = {
    totalItems,
    pageSize,
    totalPages: Math.ceil(totalItems / pageSize),
  };

  const columns = useMemo(
    () => [
      {
        Header: <TextOne variant="head">Hook</TextOne>,
        accessor: "hookName",
        id: "hookName",
        disableSortBy: true,
        Cell: ({ value }) => {
          return <div>{value}</div>;
        },
      },
      {
        Header: <TextOne variant="head">Actions</TextOne>,
        accessor: "actions",
        disableSortBy: true,
        Cell: ({ row: { original }, value }) => {
          return (
            <div className={styles["actions"]}>
              <div
                className={styles["actions-edit"]}
                onClick={openCreateEditModal}
              >
                <img src="/img/edit.svg" alt="edit" />
              </div>
              <div className={styles["actions-plane"]}>
                <img src="/img/plan.svg" alt="plane" />
              </div>
            </div>
          );
        },
      },
    ],
    []
  );
  return (
    <>
      <DataTable
        columns={columns}
        data={
          data || []
          //   [
          //   {
          //     url: "ak_test_5AECIU1VUufMck8Fe34bkLNSvQthe2wj9dC0",
          //     key: "ak_test_5AECIU1VUufMck8Fe34bkLNSvQthe2wj9dC0",
          //     status: "Enabled",
          //     created: "05/04/2022",
          //     isRevealed: false,
          //   },
          // ]
        }
        tableStyles={tableStyles}
        paginationConfig={paginationConfig}
        // handlePaginationChange={props.handlePaginationChange}
      />
      <input id="textcopy" className="link-input" style={{ display: "none" }} />
    </>
  );
};

export default WebhooksTable;
