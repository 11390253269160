import React, { Component } from 'react';
import {AiOutlinePlus} from 'react-icons/ai';
import cx from 'classnames';
import styles from './_container.module.scss';
import { TextOne } from '../text-one';

class Container extends Component {
  _onClick = () => {
    this.props.onButtonClick && this.props.onButtonClick();
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={cx(styles.header,{
          [styles.headerPadding]: this.props.isPadding
        })}>
          <div className={styles.left}>
            {this.props.title || ''}
          </div>
          <div className={styles.right}>
            {
              this.props.onButtonClick
              ?
                <button 
                  className={styles.button}
                  onClick={this._onClick}
                >
                  <AiOutlinePlus/>
                  <TextOne>{this.props.buttonText || 'create new'}</TextOne>
                </button>
              :
              null
            }
            {this.props.rightContent && this.props.rightContent}            
          </div>
        </div>
        <div className={styles.body}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default Container;
