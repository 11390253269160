import _ from "lodash";
// import moment from 'moment'

class Helpers {
  // get amount from a percentage of value.
  static percentToAmount(percent, amount) {
    return amount ? Number((percent * amount) / 100) : 0;
    // return amount ? this.round((percent * amount) / 100, precision) : 0
  }

  // find the rounded of number, accepts precision(decimal places)
  static round(value, precision = 2) {
    const multiplier = 10 ** (precision || 0);
    return (
      Math.round((Number(value) + Number.EPSILON) * multiplier) / multiplier
    );
  }

  // find the rounded of number, accepts precision(decimal places)
  static roundDown(value, precision = 2) {
    const multiplier = 10 ** (precision || 0);
    return (
      Math.floor((Number(value) + Number.EPSILON) * multiplier) / multiplier
    );
  }

  // add/subtracts weekdays only
  static addWeekdays(date, days) {
    // let d = date ? moment(date) : moment()
    let inc = days / Math.abs(days);

    while (days != 0) {
      date.add(inc, "days");
      if (date.isoWeekday() != 6 && date.isoWeekday() != 7) {
        // saturday(6) & sunday(7)
        days -= inc;
      }
    }
    return date;
  }

  // Find duplicate entries in an array
  static findDuplicatesInArray(arr) {
    const copactArr = _.compact(arr);
    const items = {};
    const result = [];

    copactArr.forEach((item) => {
      if (items[item]) {
        items[item] += 1;
      } else {
        items[item] = 1;
      }
    });

    for (let i in items) {
      if (items[i] >= 2) {
        result.push(i);
      }
    }

    return result;
  }

  static switchMode(event) {
    if (event.target.checked) {
      window.open("https://backoffice.thesavvyapp.in/", "_self");
    } else {
      window.open("https://backoffice.savvyapp.in/", "_self");
    }
  }

  static getDateExcludingWeekends(noOfDaysToAdd = 5) {
    const startDate = new Date();
    let endDate = "",
      count = 0;
    while (count < noOfDaysToAdd) {
      endDate = new Date(startDate.setDate(startDate.getDate() + 1));
      if (endDate.getDay() != 0 && endDate.getDay() != 6) {
        //Date.getDay() gives weekday starting from 0(Sunday) to 6(Saturday)
        count++;
      }
    }
    return endDate;
  }

  static interpolateColorHex(color1, color2, minScore, maxScore, score) {
    // Convert hex color to RGB
    const factor = (score - minScore) / (maxScore - minScore);

    const hexToRgb = (hex) => hex.match(/\w\w/g).map(x => parseInt(x, 16));
    const rgb1 = hexToRgb(color1);
    const rgb2 = hexToRgb(color2);

    // Interpolate RGB components
    const interpolateComponent = (comp1, comp2, factor) =>
        Math.round(comp1 + (comp2 - comp1) * factor);

    const r = interpolateComponent(rgb1[0], rgb2[0], factor);
    const g = interpolateComponent(rgb1[1], rgb2[1], factor);
    const b = interpolateComponent(rgb1[2], rgb2[2], factor);

    // Convert interpolated RGB back to hex
    const componentToHex = (c) => {
        const hex = c.toString(16);
        return hex.length === 1 ? '0' + hex : hex;
    };

    return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
  }

  static interpolateColorHSV(color1, color2, minScore, maxScore, score) {
    // Convert hex color to RGB
    const factor = (score - minScore) / (maxScore - minScore);

    const [h1, s1, v1] = this.hexToHsv(color1);
    const [h2, s2, v2] = this.hexToHsv(color2);
    
    const dh = Math.abs(h2 - h1) <= 0.5 ? Math.abs(h2 - h1) : 1 - Math.abs(h2 - h1);
    const ds = s2 - s1;
    const dv = v2 - v1;

    const hInterpolated = (h1 + dh * factor) % 1;
    const sInterpolated = s1 + ds * factor;
    const vInterpolated = v1 + dv * factor;

    return this.hsvToHex(hInterpolated, sInterpolated, vInterpolated);
  }

  static hexToHsv(hex) {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => {
        return r + r + g + g + b + b;
    });
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    let r = parseInt(result[1], 16) / 255;
    let g = parseInt(result[2], 16) / 255;
    let b = parseInt(result[3], 16) / 255;

    const max = Math.max(r, g, b),
        min = Math.min(r, g, b);
    let h,
        s,
        v = max;

    const d = max - min;
    s = max === 0 ? 0 : d / max;

    if (max === min) {
        h = 0; // achromatic
    } else {
        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0);
                break;
            case g:
                h = (b - r) / d + 2;
                break;
            case b:
                h = (r - g) / d + 4;
                break;
        }
        h /= 6;
    }

    return [h, s, v];
  }

  static hsvToHex(h, s, v) {
    let r, g, b;
    let i = Math.floor(h * 6);
    let f = h * 6 - i;
    let p = v * (1 - s);
    let q = v * (1 - f * s);
    let t = v * (1 - (1 - f) * s);

    switch (i % 6) {
        case 0:
            (r = v), (g = t), (b = p);
            break;
        case 1:
            (r = q), (g = v), (b = p);
            break;
        case 2:
            (r = p), (g = v), (b = t);
            break;
        case 3:
            (r = p), (g = q), (b = v);
            break;
        case 4:
            (r = t), (g = p), (b = v);
            break;
        case 5:
            (r = v), (g = p), (b = q);
            break;
    }

    const toHex = (x) => {
        const hex = Math.round(x * 255).toString(16);
        return hex.length === 1 ? '0' + hex : hex;
    };

    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  }

}

export { Helpers };
