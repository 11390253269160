import { takeEvery, call, put } from "redux-saga/effects";
import { ACTION_TYPES } from "src/constants";
import {
  adminWebhooksList,
  adminCurrentWebhooks,
  adminUpdateWebhook,
} from "src/redux/api";
import {
  requestAdminWebhookListSuccess,
  requestAdminWebhookListFailed,
  requestAdminCurrentWebhookSuccess,
  requestAdminCurrentWebhookFailed,
  requestAdminUpdateWebhookSuccess,
  requestAdminUpdateWebhookFailed,
} from "src/redux/actions";

export function* watchAdminWebhookRequests() {
  yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_WEBHOOKS, requestAdminWebhookList);
  yield takeEvery(
    ACTION_TYPES.REQUEST_ADMIN_CURRENT_WEBHOOKS,
    requestAdminCurrentWebhook
  );
  yield takeEvery(
    ACTION_TYPES.REQUEST_ADMIN_UPDATE_WEBHOOKS,
    requestAdminUpdateWebhook
  );
}

function* requestAdminWebhookList(action) {
  try {
    const webhooks = yield call(adminWebhooksList, action.payload);
    yield put(requestAdminWebhookListSuccess(webhooks?.hooks || []));
  } catch (e) {
    yield put(requestAdminWebhookListFailed(e));
  }
}

function* requestAdminCurrentWebhook(action) {
  try {
    const webhooks = yield call(adminCurrentWebhooks, action.payload);
    yield put(requestAdminCurrentWebhookSuccess(webhooks?.webhook || {}));
  } catch (e) {
    yield put(requestAdminCurrentWebhookFailed(e));
  }
}

function* requestAdminUpdateWebhook(action) {
  try {
    const webhooks = yield call(adminUpdateWebhook, action.payload);
    yield put(requestAdminUpdateWebhookSuccess(webhooks?.webhook || {}));
  } catch (e) {
    yield put(requestAdminUpdateWebhookFailed(e));
  }
}
