import { ACTION_TYPES } from "src/constants";
import { INITIAL_STATE } from "../store";
import _ from 'lodash';

export default function AdminLandingPagesReducer(
  state = INITIAL_STATE.landingPages,
  action
) {
  switch (action.type) {
    case ACTION_TYPES.REQUEST_LANDING_PAGES_SUCCESS:{
      return [...action.payload.landingPages];
    }

    case ACTION_TYPES.REQUEST_LANDING_PAGES_FAILED:
      return INITIAL_STATE.landingPages;

    case ACTION_TYPES.REQUEST_CREATE_LANDING_PAGE_SUCCESS:
      return [...state, action.payload.landingPage]

    case ACTION_TYPES.REQUEST_UPDATE_LANDING_PAGE_SUCCESS: {
      const updateIndex = _.findIndex(state, (landingPage) => landingPage.uuid == action.payload.landingPage.uuid)

      return state.map((landingPage, index) => {
        if (index !== updateIndex) {
          return landingPage;
        }

        return {
          ...action.payload.landingPage
        }
      });
      return 
    }

    case ACTION_TYPES.REQUEST_ADMIN_LOGIN:
    case ACTION_TYPES.REQUEST_ADMIN_LOGOUT:
    case ACTION_TYPES.ADMIN_LOGOUT_SUCCESS:
      return INITIAL_STATE.landingPages

    default:
      return state;
  }
}
