import { connect } from "react-redux";
import {
  requestAdminGenericLinksList,
  requestAdminCreateGenericLinkClear
} from "src/redux/actions";
import GenericLinks from "./generic-links";

const mapStateToProps = (state) => ({
  genericLinks: state.adminGenericLinks?.genericLinksList,
});

export default connect(mapStateToProps, {
  requestAdminGenericLinksList,
  requestAdminCreateGenericLinkClear,
})(GenericLinks);
