import { takeEvery, call, put } from "redux-saga/effects";
import { ACTION_TYPES } from "src/constants";
import { createLeadRecommenationsApi } from "src/redux/api";
import {
    createLeadRecommendationsSuccess,
    createLeadRecommendationsFailure,
} from "src/redux/actions";

export function* watchCreateLeadRecommendationsRequest() {
  yield takeEvery(ACTION_TYPES.REQUEST_CREATE_LEAD_RECOMMENDATIONS, requestCreateLeadRecommendations);
}

function* requestCreateLeadRecommendations(action) {
  try {
    const createLeadRecommendationsData = yield call(createLeadRecommenationsApi, action.payload);
    yield put(createLeadRecommendationsSuccess(createLeadRecommendationsData));
  } catch (e) {
    yield put(createLeadRecommendationsFailure(e));
  }
}