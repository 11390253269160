import { Helpers } from "src/helpers";
import React, { useEffect, useState } from "react";
import { DataTable } from "../../../../../components";
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarExport } from '@mui/x-data-grid';
import Popover from '@mui/material/Popover';
import { Divider, Skeleton, Grid } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LinearProgress from '@mui/material/LinearProgress';
import SeeAllRecommendation from "../see-all-recommendation";
import AddRecommendationDialog from "../add-recommendation-dialog";
import AllInteractionsDialog from "../all-interactions-dialog";
import RecommendIcon from '@mui/icons-material/Recommend';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import DeleteDialog from "../delete-dialog";
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ProfileDialog from "../profile-dialog";
import Chip from '@mui/material/Chip';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import moment from "moment";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import TextField from '@mui/material/TextField';
import CreateNotes from '../notes';
import { teal, orange, blue, red, grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { gridPageCountSelector,GridPagination,useGridApiContext,
useGridSelector,} from '@mui/x-data-grid';
import MuiPagination from '@mui/material/Pagination';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import './leads-table.scss';
import { toast } from "react-toastify";

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <HourglassEmptyIcon/>
      <Box sx={{ mt: 1 }}>No Rows</Box>
    </StyledGridOverlay>
  );
}

function LinearProgressWithLabel(props) {
  const color = Helpers.interpolateColorHSV("#d32f2f", "#00796b", 0, 100, props.value)
  const lightColor = Helpers.interpolateColorHex(color, "#ffffff", 0, 100, 70)
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress
          variant="determinate" {...props}
          style={{backgroundColor: lightColor}}
          sx={{
            '& .MuiLinearProgress-bar': {
              backgroundColor: color,
            },
          }}/>
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" style={{fontWeight: 600}} color={color}>{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
const LeadsTable = (props) => {
  const [showAllRecommendationsRow, setShowAllRecommendationsRow] = useState(null)
  const [showDeleteDialogRow, setShowDeleteDialogRow] = useState(null)
  const [showAddRecommendationRow, setShowAddRecommendationRow] = useState(null)
  const [showProfileRow, setShowProfileRow] = useState(null)
  const [selectedMore, setSelectedMore] = useState(null);
  const [selectedMoreAnchor, setSelectedMoreAnchor] = useState(null);
  const [showAddNotesRow, setShowAddNotesRow] = useState(null);
  const [showInteractionsRow, setShowInteractionsRow] = useState(null)
  const[isopenGraph,setIsOpenGraph] = useState(false)
  const[recIndex,setrecIndex]=useState(null)

  const tableStyles = {
    bodyRow: {
      backgroundColor: "#ffffff",
      borderBottomColor: "#f3f3f3",
    },
    tableHeader: {
      backgroundColor: "#ffffff",
      borderTop: "1px solid #F3F3F3",
      borderBottom: "1px solid #F3F3F3",
    },
  };

  const { totalItems, data, isLoading } = props;
  const [filterdData, setFilteredData] = useState([]);
  const [isloading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000)
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setFilteredData(props.data);
    }
  }, [isLoading, props.data]);
  const pageSize = 50;
  const paginationConfig = {
    totalItems,
    pageSize,
    totalPages: Math.ceil(totalItems / pageSize),
  };
  const [expandedRows, setExpandedRows] = useState({});
  const toggleRow = (rowId) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [rowId]: !prevState[rowId],
    }));
  };

  function calculateGrowth(r) {
    const navAtRecommendationDate = r.navAtRecommendationDate;
    const performanceofNav = r.performance;
    const formatePerformance = {};
    _.map(performanceofNav, (value, key) => {
      if (r.performanceType == "monthly") {
        let parsedDate = moment(key, 'MMMMYYYY');
        let formattedDate = parsedDate.format('MM/YYYY');
        formatePerformance[formattedDate] = value;
      } else {
        let parsedDate = moment(key, 'DDMMYYYY');
        let formattedDate = parsedDate.format('DD/MM/YYYY');
        formatePerformance[formattedDate] = value;
      }
    });
    const sortPerformanceByDate = _.sortBy(Object.entries(formatePerformance), ([key]) => {
      if (r.performanceType == "monthly") {
        const date = moment(key, 'MM/YYYY').toDate();
        return date;
      } else {
        const date = moment(key, 'DD/MM/YYYY').toDate();
        return date;
      }
    });
    const convertPerformance = _.fromPairs(sortPerformanceByDate);
    const performance = _.values(convertPerformance);
    const lastValueOfIndex = _.last(performance);
    const growthNav = ((parseFloat(lastValueOfIndex) - parseFloat(navAtRecommendationDate)) / parseFloat(navAtRecommendationDate)) * 100;
    const res = !isNaN(growthNav) ? `${growthNav.toFixed(2)}%` : "N/A";
    return {
      value: res,
      isPositive: growthNav > 0
    };
  }


  const columns =
    [
      {
        Header:'Lead Name',
        accessor: "fullName",
        id: "fullName",
        disableSortBy: false,
        Cell: ({ row, value }) => {
          return (
            <div
              style={{ cursor: "pointer", width: "70%" }}
            >
              {value}
            </div>
          );
        },
      },
      {
        Header: 'Recommendations',
        accessor: "highestGrowth",
        id: 'Recommendations',
        disableSortBy: true,
        flex: 2.1,
        
        Cell: ({ row }) => {
          let value = row.leadRecommendations
          const recommendations = _.map(value);
          let highestGrowth = -Infinity; 
          _.forEach(recommendations, (r) => {
            const growth = calculateGrowth(r);
            if (!isNaN(parseFloat(growth.value)) && parseFloat(growth.value) > highestGrowth) {
              highestGrowth = parseFloat(growth.value);
            }
          });

          const handleSingleRec = (recUuid) => {
            let recommendationUuid = recUuid[0]?.uuid
            if (_.head(isNotNavValue)) {
              setShowAllRecommendationsRow(row);
              setIsOpenGraph(true);
              setrecIndex(recommendationUuid)
            } else {
              toast.error("Data unavailable");
              setIsOpenGraph(false);
            }
          }

          const clickMoreFund = (recommedation) => {
            setShowAllRecommendationsRow(row);
            setrecIndex(recommedation.uuid)
          };

          const shouldCollapse = expandedRows[row.id] && _.size(value) > 1;
          const moreText = _.size(recommendations) > 1 ? ` + ${_.size(recommendations) - 1} more` : '';
          const sortedRecommendations = _.orderBy(recommendations, (r) => {
            const growth = calculateGrowth(r);
            return isNaN(parseFloat(growth.value)) ? -Infinity : parseFloat(growth.value);
          }, 'desc');
          const recommendationsname = _.map(sortedRecommendations, 'fund.name');
          const isNotNavValue = _.map(sortedRecommendations, 'navAtRecommendationDate');
          if(_.isEmpty(value))
            return <span style={{ color: grey[600], fontWeight: 500 }}>No recommendations yet.</span>
          else
          return (
            <div
              onClick={() => toggleRow(row.uuid)}
              style={{ cursor: "pointer", width: "100%" }}
            >
              <div style={{ display: 'flex', alignItems: 'center', }}>
                { _.size(value) <= 1 ? <div /> : shouldCollapse ? <ArrowDropDownIcon /> : <ArrowRightIcon /> }
                {shouldCollapse ?
                  <Chip icon={<RecommendIcon fontSize="small" />} label={`Recommendations for ${row.fullName}`} />
                  : 
                  value.length === 1 ?
                  (
                    <>
                      <span onClick={ () => handleSingleRec(value)}>
                      <p>{_.head(recommendationsname)}</p>
                      {
                        _.head(isNotNavValue) ?
                          <>{
                            highestGrowth > 0 ?
                            <>
                            <StackedLineChartIcon style={{ color: teal[700], fontSize: '15px' }} />
                            <span style={{ color: blue[900], fontWeight: 500 }}>Growth By </span>
                            <span style={{ color: teal[700] }}>{`${highestGrowth}%`}</span>
                            </>
                            :
                            <>
                            <StackedLineChartIcon style={{ color: red[700], fontSize: '15px' }} />
                            <span style={{ color: red[900], fontWeight: 500 }}>Loss By </span>
                            <span style={{ color: red[700] }}>{`${highestGrowth}%`}</span>
                            </>
                          }
                            
                          </>
                        :
                          <>
                            <StackedLineChartIcon style={{ color: orange[800], fontSize: '15px' }} />
                            <span style={{ color: grey[600], fontWeight: 500 }}>Data unavailable</span>
                          </>
                      }
                      {moreText}
                    </span>
                  </>
                  )
                  :
                  (
                    <span>
                      <p>{_.head(recommendationsname)}</p>
                      {
                        _.head(isNotNavValue) ?
                          <>{
                            highestGrowth > 0 ?
                            <>
                            <StackedLineChartIcon style={{ color: teal[700], fontSize: '15px' }} />
                            <span style={{ color: blue[900], fontWeight: 500 }}>Growth By </span>
                            <span style={{ color: teal[700] }}>{`${highestGrowth}%`}</span>
                            </>
                            :
                            <>
                            <StackedLineChartIcon style={{ color: red[700], fontSize: '15px' }} />
                            <span style={{ color: red[900], fontWeight: 500 }}>Loss By </span>
                            <span style={{ color: red[700] }}>{`${highestGrowth}%`}</span>
                            </>
                          }
                            
                          </>
                        :
                          <>
                            <StackedLineChartIcon style={{ color: orange[800], fontSize: '15px' }} />
                            <span style={{ color: grey[600], fontWeight: 500 }}>Data unavailable</span>
                          </>
                      }

                      {moreText}
                    </span>
                  )
                }
              </div>
              <Collapse in={shouldCollapse}>
                <List>
                  {_.map(sortedRecommendations, (r) => {
                    const growth = calculateGrowth(r);
                    return (
                      <div>
                        <ListItem onClick={() => clickMoreFund(r)}  style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }} >
                          <ArrowRightAltIcon />
                          <p style={{ width: '45%' }}>{r.fund.name}</p>
                          <span style={{ width: '45%' }}>
                            <span> Recommended on <CalendarMonthOutlinedIcon style={{ fontSize: '15px', color: "blue" }} />{r.recommendationDate} <br/> </span>
                            {
                              growth.value == "N/A" ?
                              <>
                                <StackedLineChartIcon style={{ color: orange[800], fontSize: '15px' }} />
                                <span style={{ color: grey[600], fontWeight: 500 }}>Data unavailable</span>
                              </>
                              :
                              <>
                                <StackedLineChartIcon style={{ color: growth.isPositive ? teal[700] : red[800], fontSize: '15px' }} />
                                <span style={{ color: blue[900], fontWeight: 500 }}>{growth.isPositive ? 'Growth By' : "Loss By"}  </span>
                                <span style={{ color: growth.isPositive ? teal[700] : red[800] }}>{growth.value}</span>
                              </>
                            }
                          </span>
                        </ListItem>
                      </div>
                    );
                  })}
                </List>
              </Collapse>
            </div>
          );
        },
      },
      {
        Header: 'Relationship Strength',
        accessor: "relationshipStrength",
        id:'relationshipStrength',
        disableSortBy: false,
        Cell: ({ row, value }) => {
          return (
            <div style={{ cursor: "pointer", width: "100%" }}>
              {value ? 
                <LinearProgressWithLabel thickness={4} value={value * 10} />
              :
                "N/A"
              }
            </div>
          )
        },
      },
      {
        Header: 'Created on',
        accessor: "createdAt",
        id: "created",
        headerClassName: 'createdStyle',
        flex: 0.7,
        disableSortBy: false,
        Cell: ({ row }) => { 
          const timestamp = row.createdAt;
          const formattedDate = moment.unix(timestamp).format('DD-MM-YYYY');
          return (
            <div style={{ cursor: "pointer", width: "100%", marginLeft: '20px' }}>
              {formattedDate} 
            </div>
          )
        }
      },
      
      {
        Header:'Status',
        accessor: "status",
        flex:0.7,
        disableSortBy: false,
        headerClassName: 'statusStyle',
        Cell: ({ row, value }) => {
          return (
            <div style={{ cursor: "pointer", margin: "auto", width: "50%" }}>
              {value}
            </div>
          )
        },
      },
      {
        Header: '',
        accessor: "more",
        id: "more",
        flex: 0.2,
        disableSortBy: true,
        Cell: ({ row, value }) => {
          return (
            <div
              style={{ cursor: "pointer", width: "100%" }}
            >
              <MoreVertIcon onClick={(event) => handleMoreVertIcon(event, row)} />
              <Popover
                open={selectedMore == row.uuid}
                anchorEl={selectedMoreAnchor}
                onClose={handleCloseCard}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}>

                <MenuList>
                  <MenuItem onClick={() => {
                    handleCloseCard()
                    setShowAddNotesRow(row)
                  }}>
                    <ListItemText>Add interaction</ListItemText>
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={() => {
                    handleCloseCard()
                    setShowInteractionsRow(row)
                  }}>
                    <ListItemText>Interaction timeline</ListItemText>
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={() => {
                    handleCloseCard()
                    setShowAllRecommendationsRow(row)
                  }}>
                    <ListItemText>All recommendations</ListItemText>
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={() => {
                    handleCloseCard()
                    setShowAddRecommendationRow(row)
                  }}>
                    <ListItemText>New recommendation</ListItemText>
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={() => {
                    handleCloseCard()
                    setShowProfileRow(row)
                  }}>
                    <ListItemText>Profile</ListItemText>
                  </MenuItem>
                   <Divider />
                  <MenuItem onClick={() => {
                    handleCloseCard()
                    props.requestConvertToSaleLead(row.uuid);
                  }}>
                    <ListItemText>Mark as converted!</ListItemText>
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={() => {
                    handleCloseCard()
                    setShowDeleteDialogRow(row)
                  }}>
                    <ListItemText>Delete</ListItemText>
                  </MenuItem>
                </MenuList>
              </Popover>
            </div>
          );
        },
      },
    ]

  const handleMoreVertIcon = (event, row) => {
    setSelectedMoreAnchor(event.currentTarget)
    setSelectedMore(row.uuid)
  }
  const handleCloseCard = () => {
    setSelectedMoreAnchor(null)
    setSelectedMore(null)
  }
  const handleSearch = (e) => {
    let searchQuery = e.target.value
    const searchData = data.filter(item => {
      const fullNameMatch = item.fullName.toLowerCase().includes(searchQuery.toLowerCase());
      const leadRecommendationsMatch = item.leadRecommendations.some(recommendation => {
        return recommendation.fund.name.toLowerCase().includes(searchQuery.toLowerCase());
      });
      return fullNameMatch || leadRecommendationsMatch;
    });
    setFilteredData(searchData);
  }

  function CustomToolbar() {
    return (
      <div style={{ display: 'flex' }}>
        <GridToolbarContainer style={{ width: "100%" }}>
          <GridToolbarColumnsButton
           slotProps={{
            button: { variant: 'outlined' },
            }}
           style={{ color: '#000', }} />
          <GridToolbarExport
           slotProps={{
            button: { variant: 'outlined' },
          }}
           style={{ color: '#000', }} />
        </GridToolbarContainer>
      </div>
    );
  }

  const sortedData = _.orderBy(filterdData, (row) => {
    let highestGrowth = Number.MIN_VALUE;
    _.forEach(row.leadRecommendations, (r) => {
      const growth = calculateGrowth(r);
      if (!isNaN(parseFloat(growth.value)) && parseFloat(growth.value) > highestGrowth) {
        highestGrowth = parseFloat(growth.value);
      }
    });
    return highestGrowth;
  }, 'desc');

  const sortedDataWithHighestGrowth = sortedData.map(row => {
    let highestGrowth = Number.MIN_VALUE;
    row.leadRecommendations.forEach((r) => {
      const growth = calculateGrowth(r);
      if (!isNaN(parseFloat(growth.value)) && parseFloat(growth.value) > highestGrowth) {
        highestGrowth = parseFloat(growth.value);
      }
    });
    return {
      ...row,
      highestGrowth: highestGrowth
    };
  });
  
  const sortByCreatedDate = _.orderBy(sortedDataWithHighestGrowth, ['createdAt' ], ['desc']);

  const numberOfHeadSkeletons = 2;
  const skeletonElements = _.times(numberOfHeadSkeletons, index => (
    <Skeleton
      key={index}
      sx={{ bgcolor: 'grey' }}
      variant="rectangular"
      width={90}
      height={15}
    />
  ));
  const tableDataSkeletons = 7;
  const DataSkeletons = _.times(tableDataSkeletons, index => (
    <Skeleton
      key={index}
      sx={{ bgcolor: 'gray', marginTop: 2 }}
      variant="rectangular"
      width="100%"
      height="10vh"
    />
  ));
  
function Pagination({ page, onPageChange, className }) {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <MuiPagination
      color="primary"
      className={className}
      count={pageCount}
      page={page + 1}
      onChange={(event, newPage) => {
        onPageChange(event, newPage - 1);
      }}
    />
  );
}
  function CustomPagination(props) {
    return (
    <div style={{ marginRight:'40%'}}>
      <GridPagination ActionsComponent={Pagination} {...props} />
    </div>
    )
  }
  return (
    <div>
      {isloading ? <div>
        <Grid container wrap="nowrap" gap={1}>
          {skeletonElements}
        </Grid>
        {DataSkeletons}
      </div> : <div>
        <div style={{ marginRight: 'auto', width: '30%', marginBottom: 15, }}>
          <Paper component="form"
            sx={{ display: 'flex', alignItems: 'center', boxShadow: "none" }}
          >
            <IconButton sx={{ p: '1px' }} aria-label="search">
              <SearchIcon />
            </IconButton>
            <TextField id="standard-basic" variant="standard"
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search by lead or Recommendation"
              onChange={(e) => handleSearch(e)} />
          </Paper>
        </div>
        <DataTable
          columns={columns}
          data={sortByCreatedDate}
          tableStyles={tableStyles}
          pagination
          getRowHeight={() => 'auto'}
          sx={{
            '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
            '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
          }}
          slots={{
            toolbar: CustomToolbar,
            noRowsOverlay: CustomNoRowsOverlay,
            pagination: CustomPagination,
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: 25 }},
          }}
          autoHeight
        />
      </div>}
      {
        showAllRecommendationsRow?.uuid &&
        <SeeAllRecommendation
          lead={showAllRecommendationsRow}
          showLeads={true}
          requestShowLead={props.requestShowLead}
          isOpen={showAllRecommendationsRow?.uuid}
          openSingleGraph={recIndex}
          onClose={() => {
            setShowAllRecommendationsRow(null);
            setIsOpenGraph(false)
            setrecIndex(null)
          } }
        />
      }
      {
        showDeleteDialogRow?.uuid &&
        <DeleteDialog
          lead={showDeleteDialogRow}
          isOpen={showDeleteDialogRow?.uuid}
          onClose={() => setShowDeleteDialogRow(null)}
        />
      }
      {
        showAddRecommendationRow?.uuid &&
        <AddRecommendationDialog
          name={showAddRecommendationRow?.fullName}
          id={showAddRecommendationRow?.uuid}
          isOpenDialog={showAddRecommendationRow?.uuid}
          onClose={() => setShowAddRecommendationRow(null)}
        />
      }
      {
        showProfileRow?.uuid &&
        <ProfileDialog
          lead={showProfileRow}
          isOpen={showProfileRow?.uuid}
          
          onClose={() => setShowProfileRow(null)}
        />
      }
      { showAddNotesRow?.uuid &&
        <CreateNotes
          isOpen={showAddNotesRow?.uuid}
          onClose={() => setShowAddNotesRow(null)}
          lead={showAddNotesRow}
        />
      }
      {
        showInteractionsRow?.uuid &&
        <AllInteractionsDialog
          isOpen={showInteractionsRow?.uuid}
          onClose={() => setShowInteractionsRow(null)}
          lead={showInteractionsRow}
        />
      }
    </div>
  );
};

export default LeadsTable;
