import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import cx from 'classnames';
import './_datatable.scss';
import styles from './_datatable.module.scss';
import _ from 'lodash';

const DataTable = (props) => {
  const { columns, data } = props;
  const cols = _.map(columns, (c) =>(
    {
      field: c.accessor, headerName: c.Header,
      headerClassName:c.headerClassName,
      flex: c.flex || 1, renderCell: c.Cell,
    }
  ))
  const rows = _.map(data, (d) => ({ ...d, id: d.uuid || Math.random()}))

  return (
    <>
      <div>
        <DataGrid {...props} autoHeight columns={cols} rows={rows} />
      </div>
    </>
  );
};

export default DataTable;
