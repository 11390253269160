import { ACTION_TYPES } from "src/constants";
import { INITIAL_STATE } from "../store";

export default function AdminFundsReducer(
  state = INITIAL_STATE.adminFunds,
  action
) {
  switch (action.type) {
    case ACTION_TYPES.ADMIN_FUNDS_SUCCESS:
      return [...action.payload];

    case ACTION_TYPES.ADMIN_FUNDS_FAILED:
      return INITIAL_STATE.adminFunds;

    case ACTION_TYPES.REQUEST_ADMIN_LOGIN:
    case ACTION_TYPES.REQUEST_ADMIN_LOGOUT:
    case ACTION_TYPES.ADMIN_LOGOUT_SUCCESS:
      return INITIAL_STATE.adminFunds;

    default:
      return state;
  }
}
