import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import EventBus from "eventing-bus";
import Header from "src/components/header";
import {Fab,} from "@mui/material";
import styles from "./_crm-dashbord.module.scss";

import CreateLead from "./create-lead";
import LeadsTable from "./leads-table";

import CreateNotes from "./notes";
import { ACTION_TYPES } from "src/constants";
import Container from '@mui/material/Container';
import _ from 'lodash';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import NoteAddIcon from '@mui/icons-material/NoteAdd';

class CrmDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      searchValue: "",
      open: false,
      openCreateNotes: false,
      openNewRecommendation: false,
      currentPage: 1,
      itemsPerPage: 25,
    };
  }
 
  componentDidMount() {
    this.requestLead = EventBus.on(
      ACTION_TYPES.REQUEST_LEAD,
      () => this.setState({ isLoading: true })
    );
    this.leadSuccess = EventBus.on(
      ACTION_TYPES.LEAD_SUCCESS,
      this.handleCrmSuccess
    );
    this.leadFailure = EventBus.on(
      ACTION_TYPES.LEAD_FAILURE,
      () => {
        this.setState({ isLoading: false })}
    );

    this.props.requestLead();
  }

  componentWillUnmount() {
    this.requestLead();
    this.leadSuccess();
    this.leadFailure();
  }
    
  handleCrmSuccess = () => {
    this.props.requestAdminAmcs()
    this.setState({ isLoading: false });
  };

  handleClickOpen = () => {
    this.setState({ open: !this.state.open });
  };

  handleClose = () => {
    this.setState({ open: !this.state.open });
  };

  handleOpenCloseCreateNotes = () => {
    this.setState({ openCreateNotes: !this.state.openCreateNotes })
  }

  render() {
    const title = "Leads Management";
    const {leads , requestLead} = this.props;

    return (
      <div>
        <Header isCrm={true} title={title} />
        <div>
          <LeadsTable
            isLoading={this.state.isLoading}
            data={leads}
            handlePaginationChange={() => {}}
            totalItems={_.size(leads)}
          />
          <Container className={styles["pagination-container"]} >
            <div className={styles["footer-btn"]}>
              <div>
                <Fab variant="extended" onClick={this.handleClickOpen} style={{marginRight: 8}}>
                  <PersonAddIcon sx={{ mr: 1 }} />
                  Add lead(s)
                </Fab>
                <Fab
                  className={styles["plus-btn"]}
                  aria-label="add"
                  variant="extended"
                  onClick={this.handleOpenCloseCreateNotes}
                >
                  <NoteAddIcon sx={{ mr: 1 }} />
                  Add note(s)
                </Fab>
                {
                  this.state.open &&
                    <CreateLead
                      isOpen={this.state.open}
                      onClose={this.handleClose}
                      requestLead={requestLead}
                      openNewRecommendation={this.state.openNewRecommendation}
                    />
                }
                {
                  this.state.openCreateNotes && 
                    <CreateNotes
                      isOpen={this.state.openCreateNotes}
                      onClose={this.handleOpenCloseCreateNotes}
                    />
                }
              </div>
            </div>
          </Container>
        </div>
      </div>
    );
  }
}

export default withRouter(CrmDashboard);
