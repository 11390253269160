import { ApiRequest } from "src/helpers";
import { API_END_POINTS } from "src/constants";

export function adminLogin(data) {
  return ApiRequest.fetchAdmin({
    method: "post",
    url: API_END_POINTS.ADMIN_LOGIN,
    headers: {
      Authorization: `Email ${btoa(data?.email + ":" + data?.password)}`,
    },
  }).then((response) => ({
    token: response.data.token,
    partner: response.data.partner,
    otpId: response.data.otpId,
    adminUser: response.data.partnerAdminUser,
    partnerPublicAccessKey: response.data.partnerPublicAccessKey,
  }));
}

export function adminVerifyOtp(data) {
  return ApiRequest.fetchAdmin({
    method: "post",
    url: API_END_POINTS.ADMIN_VERIFY_OTP,
    headers: {
      Authorization: `Email ${btoa(data?.email + ":" + data?.password)}`,
    },
    data: { otp: data.otp },
  }).then((response) => ({
    token: response.data.token,
    partner: response.data.partner,
    adminUser: response.data.partnerAdminUser,
    partnerPublicAccessKey: response.data.partnerPublicAccessKey,
  }));
}

export function adminNewADLogin() {
  return ApiRequest.fetchAdmin({
    method: "post",
    url: API_END_POINTS.ADMIN_NEW_AD_LOGIN,
  }).then((response) => ({
    url: response.data.request
  }));
}

export function adminVerifyToken(data) {
  return ApiRequest.fetchAdmin({
    method: "post",
    headers: { 
      Authorization: `Bearer ${data.token}`
    },
    url: API_END_POINTS.ADMIN_VERIFY_TOKEN,
  }).then((response) => ({
    token: response.data.token,
    partner: response.data.partner,
    adminUser: response.data.partnerAdminUser
  }));
}

export function adminLogout(data) {
  return ApiRequest.fetchAdmin({
    method: "post",
    url: API_END_POINTS.ADMIN_LOGOUT,
  }).then((response) => ({}));
}