import React, { Component } from "react";
import { FileUploader } from "react-drag-drop-files";
import { toast } from "react-toastify";
import EventBus from "eventing-bus";
import { ACTION_TYPES } from "src/constants";
import * as XLSX from "xlsx";
import cx from "classnames";
import styles from "./_batch-link.module.scss";
import { ButtonOne } from "src/components/form-inputs";
import { withRouter } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";

import _ from "lodash";

const fileTypes = ["xlsx", "xls", "csv"];
const fileKeys = [
  { column: "type", required: true, allowed_value: ["one_time", "ongoing"] },
  { column: "unique_id", required: true, allowed_value: [] },
  { column: "bank_account_number", required: false, allowed_value: [] },
  { column: "bank_ifsc_code", required: false, allowed_value: [] },
  { column: "bank_name", required: false, allowed_value: [] },
  { column: "amount", required: false, allowed_value: [] },
  { column: "fund_code", required: true, allowed_value: [] },
  { column: "folio_number", required: false, allowed_value: [] },
  { column: "holding_mode", required: false, allowed_value: ["SI"] },
  { column: "investor_name", required: true, allowed_value: [] },
  { column: "investor_phone_number", required: true, allowed_value: [] },
  { column: "investor_email", required: true, allowed_value: [] },
  { column: "investor_pan_number", required: false, allowed_value: [] },
  { column: "sip_day", required: false, allowed_value: [] },
  { column: "number_of_sip_installments", required: false, allowed_value: [] },
  { column: "sip_start_date", required: true, allowed_value: [] },
  { column: "sip_end_date", required: false, allowed_value: [] },
  { column: "sip_frequency", required: false, allowed_value: [] },
  { column: "distributor_code", required: false, allowed_value: [] },
  { column: "landing_page_id", required: false, allowed_value: [] },
];

class BatchLinkCreate extends Component {
  state = {
    file: null,
    fileUploadedData: null,
    fileRef: null,
    isLoading: false,
    excelFileData: [],
    uploadName: null,
    isValidForm: false,
    notificationOptions: [],
    selectedWhatsappTemplate: null,
    allowedDBVariables: [],
    allowedLinkVariables: [],
    templateVariables: [],
  };

  componentDidMount() {
    this.requestBatchUpload = EventBus.on(
      ACTION_TYPES.REQUEST_BATCH_UPLOAD,
      () => this.setState({ isLoading: true })
    );
    this.batchUploadSuccess = EventBus.on(
      ACTION_TYPES.BATCH_UPLOAD_SUCCESS,
      this.handleBatchUploadSuccess
    );
    this.batchUploadFailure = EventBus.on(
      ACTION_TYPES.BATCH_UPLOAD_FAILED,
      () => this.setState({ isLoading: false, file: null })
    );

    this.requestWhatsappTemplates = EventBus.on(
      ACTION_TYPES.REQUEST_WHATSAPP_TEMPLATES_LIST,
      () => this.setState({ isLoading: true })
    );
    this.whatsappTemplatesSuccess = EventBus.on(
      ACTION_TYPES.REQUEST_WHATSAPP_TEMPLATES_LIST_SUCCESS,
      this.handleWhatsappTemplatesSuccess
    );
    this.whatsappTemplatesFailure = EventBus.on(
      ACTION_TYPES.REQUEST_WHATSAPP_TEMPLATES_LIST_FAILURE,
      () => this.setState({ isLoading: false })
    );

    this.requestSampleSuccess = EventBus.on(
      ACTION_TYPES.REQUEST_SAMPLE_BATCH_FILE_SUCCESS,
      this._handleRequestSampleSuccess
    );
  }

  componentWillUnmount() {
    this.requestBatchUpload();
    this.batchUploadSuccess();
    this.batchUploadFailure();

    this.requestWhatsappTemplates();
    this.whatsappTemplatesSuccess();
    this.whatsappTemplatesFailure();

    this.requestSampleSuccess();
  }

  handleBatchUploadSuccess = () => {
    this.setState({ isLoading: false, file: this.state.fileRef });
    this.props.history && this.props.history.push("/admin/batch-links");
  };

  _handleRequestSampleSuccess = (data) => {
    var _viewBlob = function (data, contentType) {
      var file = new Blob([data], {
        type: contentType,
      });
      // IE won't allow opening a Blob with window.open()
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(file);
      } else {
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL, "_blank");
      }
    };
    _viewBlob(data, "text/csv");
  };
  _onChangeUploadName = (e) => {
    e.target.value = e.target.value.replace(/[^0-9A-Za-z_\-,()\s]/gi, '')
    this.setState({ uploadName: e.target.value }, () => {
      if (this.state.uploadName != null && this.state.uploadName != "") {
        this.setState({ isValidForm: true });
      } else {
        this.setState({ isValidForm: false });
      }
    });
  };

  handleChange = (file) => {
    this.setState({ isLoading: true });

    const componentStateScope = this;
    let fileReader = new FileReader();
    fileReader.onload = function () {
      let arrayBuffer = fileReader.result;
      var data = new Uint8Array(arrayBuffer);
      var arr = [];
      for (var i = 0; i !== data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      const excelSheetData = XLSX.utils.sheet_to_json(worksheet, {
        raw: false,
        defval: "",
      });

      if (excelSheetData && excelSheetData.length > 0) {
        componentStateScope.setState({
          fileUploadedData: excelSheetData,
          fileRef: file,
        });
        const excelFileData = [];
        const createObjectStructure = {
          type: "",
          unique_id: "",
          bank_account_number: "",
          bank_ifsc_code: "",
          bank_name: "",
          amount: "",
          fund_code: "",
          folio_number: "",
          holding_mode: "",
          investor_name: "",
          investor_phone_number: "",
          investor_email: "",
          investor_pan_number: "",
          sip_day: "",
          number_of_sip_installments: "",
          sip_start_date: "",
          sip_end_date: "",
          sip_frequency: "",
          distributor_code: "",
          landing_page_id: "",
        };
        let isExist = false;
        if (excelSheetData && excelSheetData.length > 0) {
          const containsAll = (obj, arr) => {
            for (let i = 0; i < arr.length; i++) {
              if (
                Object.keys(obj)
                  .map((k) => (k = typeof k == "string" ? k.trim() : k))
                  .includes(arr[i])
              ) {
                createObjectStructure[arr[i]] = obj[arr[i]];
                continue;
              } else {
                return false;
              }
            }
            if (excelSheetData.length > 3) {
              for (var i = 0; i < 3; i++) {
                excelFileData.push(excelSheetData[i]);
              }
            } else {
              for (var i = 0; i < excelSheetData.length; i++) {
                excelFileData.push(excelSheetData[i]);
              }
            }
            return true;
          };

          componentStateScope.setState({ excelFileData });
        }

        const formData = new FormData();
        formData.append("upload", file);
        formData.append(
          "bulk_upload_name",
          componentStateScope.state.uploadName
        );
        formData.append(
          "notification_methods",
          JSON.stringify(componentStateScope.state.notificationOptions)
        );
        formData.append(
          "gupshup_whatsapp_template_id",
          componentStateScope.state.selectedWhatsappTemplate?.templateId
        );

        if (
          componentStateScope.state.selectedWhatsappTemplate &&
          _.size(componentStateScope.state.templateVariables) <
            componentStateScope.state.selectedWhatsappTemplate
              .numberOfTextVariables
        ) {
          toast.error("Link copied to clipboard", "success");
          componentStateScope.setState({ isLoading: false });
        } else {
          formData.append(
            "whatsapp_notification_variables",
            JSON.stringify(componentStateScope.state.templateVariables)
          );
          componentStateScope.props.requestBatchUpload({ data: formData });
        }
      } else {
        const multifilehtml = document
          .getElementById("multifile")
          .getElementsByTagName("span")[0];
        multifilehtml.innerHTML =
          "<span>Upload</span> or drop a file right here";

        toast.error("No data found", "error");
        componentStateScope.setState({ isLoading: false });
      }
    };

    fileReader.readAsArrayBuffer(file);
  };

  handleWhatsappCheckbox = () => {
    const { notificationOptions } = this.state;
    const whatsappChecked = _.includes(notificationOptions, "whatsapp");

    if (whatsappChecked) {
      this.setState({
        notificationOptions: _.without(notificationOptions, "whatsapp"),
      });
    } else {
      this.props.requestWhatsappTemplatesList();

      this.setState({
        notificationOptions: [...notificationOptions, "whatsapp"],
      });
    }
  };

  handleChangeWhatsappTemplate = (e) => {
    const selected = _.find(this.props.whatsappTemplates, {
      templateId: e.target.value,
    });

    this.setState({
      selectedWhatsappTemplate: selected,
      templateVariables: [],
    });
  };

  handleWhatsappTemplatesSuccess = (payload) => {
    this.setState({
      isLoading: false,
      allowedDBVariables: payload.dbVariables,
      allowedLinkVariables: payload.linkVariables,
    });
  };

  handleChangeDBVariable = (index, variable) => {
    const vars = this.state.templateVariables;
    const toAdd = { index: index, value: variable, type: "text" };

    this.setState({
      templateVariables: [
        ..._.pull(vars, { index: index, type: "text" }),
        toAdd,
      ],
    });
  };

  handleChangeURLPathVariable = (index, variable) => {
    const vars = this.state.templateVariables;
    const toAdd = { index: index, value: variable, type: "link" };

    this.setState({
      templateVariables: [
        ..._.pull(vars, { index: index, type: "link" }),
        toAdd,
      ],
    });
  };

  render() {
    const {
      file,
      isLoading,
      excelFileData,
      notificationOptions,
      selectedWhatsappTemplate,
      allowedDBVariables,
      templateVariables,
      allowedLinkVariables,
    } = this.state;
    const { whatsappTemplates } = this.props;
    const whatsappChecked = _.includes(notificationOptions, "whatsapp");

    return (
      <div>
        <div className={styles["batch-link"]}>
          <div className={styles["container"]}>
            <Typography style={{ paddingBottom: 16 }} variant="h5" gutterBottom>
              Create New Bulk Upload
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <TextField
                      error={
                        this.state.uploadName == null ||
                        this.state.uploadName == ""
                      }
                      helperText="Upload name is required"
                      onChange={this._onChangeUploadName}
                      maxLength={60}
                      label="Enter upload name"
                      id="bulkUploadName"
                      style={{ width: "100%" }}
                      focused
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl component="fieldset" variant="standard">
                      <FormLabel component="legend">
                        Choose notification methods (Optional)
                      </FormLabel>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={whatsappChecked}
                              onChange={this.handleWhatsappCheckbox}
                              name="Whatsapp"
                            />
                          }
                          label="WhatsApp"
                        />
                      </FormGroup>

                      {whatsappChecked && (
                        <FormControl fullWidth style={{ marginTop: 8 }}>
                          <InputLabel>Choose template</InputLabel>
                          <Select
                            labelId="select-template"
                            id="select-template"
                            value={selectedWhatsappTemplate?.templateId}
                            label="Choose template"
                            onChange={this.handleChangeWhatsappTemplate}
                          >
                            {whatsappTemplates.map((template, _) => (
                              <MenuItem value={template.templateId}>
                                {template.name}
                              </MenuItem>
                            ))}
                          </Select>

                          {selectedWhatsappTemplate && (
                            <Card sx={{ minWidth: 275 }}>
                              <CardContent>
                                <Typography
                                  sx={{ fontSize: 14 }}
                                  color="text.secondary"
                                  gutterBottom
                                >
                                  Template body
                                </Typography>

                                {selectedWhatsappTemplate.header && (
                                  <div>
                                    <Typography
                                      sx={{ fontSize: 12 }}
                                      color="text.secondary"
                                      gutterBottom
                                    >
                                      Header: {selectedWhatsappTemplate.header}
                                    </Typography>
                                    <Divider
                                      light
                                      style={{ marginTop: 4, marginBottom: 4 }}
                                    />
                                  </div>
                                )}

                                <Typography
                                  variant={"body2"}
                                  style={{ "white-space": "pre-wrap" }}
                                >
                                  {selectedWhatsappTemplate.body}
                                </Typography>

                                {selectedWhatsappTemplate.footer && (
                                  <div>
                                    <Divider
                                      light
                                      style={{ marginTop: 4, marginBottom: 4 }}
                                    />
                                    <Typography
                                      sx={{ fontSize: 12 }}
                                      color="text.secondary"
                                      gutterBottom
                                    >
                                      Footer: {selectedWhatsappTemplate.footer}
                                    </Typography>
                                  </div>
                                )}

                                <Divider
                                  light
                                  style={{ marginTop: 12, marginBottom: 12 }}
                                />

                                {_.times(
                                  selectedWhatsappTemplate.numberOfTextVariables ||
                                    0,
                                  (index) => (
                                    <FormControl
                                      fullWidth
                                      style={{ marginTop: 12 }}
                                    >
                                      <InputLabel>
                                        Choose variable {index + 1}
                                      </InputLabel>
                                      <Select
                                        labelId="select-variable"
                                        id="select-variable"
                                        value={
                                          _.find(templateVariables, {
                                            index: index,
                                            type: "text",
                                          })?.value
                                        }
                                        label="Choose variable"
                                        onChange={(e) =>
                                          this.handleChangeDBVariable(
                                            index,
                                            e.target.value
                                          )
                                        }
                                      >
                                        {allowedDBVariables.map((dbVar, _) => (
                                          <MenuItem value={dbVar}>
                                            {dbVar}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  )
                                )}

                                {selectedWhatsappTemplate.doesNeedUrlPath && (
                                  <FormControl
                                    fullWidth
                                    style={{ marginTop: 12 }}
                                  >
                                    <InputLabel>
                                      Choose CTA button path
                                    </InputLabel>
                                    <Select
                                      labelId="select-url-path-variable"
                                      id="select-url-path-variable"
                                      value={
                                        _.find(templateVariables, {
                                          index: 0,
                                          type: "link",
                                        })?.value
                                      }
                                      label="Choose URL path variable"
                                      onChange={(e) =>
                                        this.handleChangeURLPathVariable(
                                          0,
                                          e.target.value
                                        )
                                      }
                                    >
                                      {allowedLinkVariables.map(
                                        (linkVar, _) => (
                                          <MenuItem value={linkVar}>
                                            {linkVar}
                                          </MenuItem>
                                        )
                                      )}
                                    </Select>
                                  </FormControl>
                                )}
                              </CardContent>
                            </Card>
                          )}
                        </FormControl>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabel component="legend">Upload file</FormLabel>
                    <div id="multifile">
                      <FileUploader
                        multiple={false}
                        handleChange={this.handleChange}
                        name="file"
                        id="multifile"
                        types={fileTypes}
                        label={"Upload or drop a file"}
                        disabled={
                          this.state.isLoading || !this.state.isValidForm
                        }
                      />
                      <p
                        className={cx(styles["file-desc"], "text-center pt-2")}
                      >
                        {isLoading ? (
                          <p>Uploading...</p>
                        ) : (
                          <p>
                            {file
                              ? `File name: ${file?.name}`
                              : "no file uploaded yet"}
                          </p>
                        )}
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item spacing={2} xs={6}>
                <Paper style={{ padding: 10 }} variant="elevation">
                  <h4 style={{ textAlign: "center" }}>File format</h4>
                  <Table>
                    <TableHead>
                      <TableCell>
                        <strong>Column name</strong>
                      </TableCell>
                      <TableCell>
                        <strong>Required</strong>
                      </TableCell>
                    </TableHead>
                    <TableBody>
                      {fileKeys.map((fileKey) => (
                        <TableRow key={fileKey.column}>
                          <TableCell>{fileKey.column}</TableCell>
                          <TableCell align="left">
                            {String(fileKey.required)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <ButtonOne
                    type="button"
                    style={{ paddingTop: 6, width: "100%" }}
                    onClick={this.props.requestSampleBatchFile}
                  >
                    Download sample file
                  </ButtonOne>
                </Paper>
              </Grid>
            </Grid>
            <br />
          </div>
        </div>
        {excelFileData && excelFileData.length > 0 && (
          <div>
            <br />
            <p>
              {this.state.fileUploadedData.length > 3
                ? this.state.fileUploadedData.length +
                  " total records. Preview of first 3 shown"
                : this.state.fileUploadedData.length + " total records"}
            </p>
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Type</th>
                  <th>Unique ID</th>
                  <th>Bank account number</th>
                  <th>Bank ifsc code</th>
                  <th>Bank name</th>
                  <th>Amount</th>
                  <th>Fund code</th>
                  <th>Folio number</th>
                  <th>Holding mode</th>
                  <th>Investor name</th>
                  <th>Investor phone number</th>
                  <th>Investor email</th>
                  <th>Investor pan number</th>
                  <th>Sip day</th>
                  <th>Number of sip installments</th>
                  <th>Sip start date</th>
                  <th>Sip end date</th>
                  <th>Sip frequency</th>
                  <th>Distributor code</th>
                  <th>Landing page ID</th>
                </tr>
              </thead>
              <tbody>
                {excelFileData.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{data?.type}</td>
                      <td>{data?.unique_id}</td>
                      <td>{data?.bank_account_number}</td>
                      <td>{data?.bank_ifsc_code}</td>
                      <td>{data?.bank_name}</td>
                      <td>{data?.amount}</td>
                      <td>{data?.fund_code}</td>
                      <td>{data?.folio_number}</td>
                      <td>{data?.holding_mode}</td>
                      <td>{data?.investor_name}</td>
                      <td>{data?.investor_phone_number}</td>
                      <td>{data?.investor_email}</td>
                      <td>{data?.investor_pan_number}</td>
                      <td>{data?.sip_day}</td>
                      <td>{data?.number_of_sip_installments}</td>
                      <td>{data?.sip_start_date}</td>
                      <td>{data?.sip_end_date}</td>
                      <td>{data?.sip_frequency}</td>
                      <td>{data?.distributor_code}</td>
                      <td>{data?.landing_page_id}</td>
                    </tr>
                  );
                })}
                <tr></tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(BatchLinkCreate);
