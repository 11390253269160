import React, { useState } from "react";
import {
  Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, Paper, Collapse, Box, IconButton, Divider, Typography, Popover, MenuItem, MenuList, ListItemText,
  Tab, Tabs, Grid,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { LineChart, ChartsReferenceLine } from "@mui/x-charts";
import { styled } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import { red,grey } from '@mui/material/colors';
import styles from "./recommendation-table.module.scss";
import moment from 'moment';
import _, { difference } from 'lodash';

const columns = [
  { id: 'collapse', label: '', maxWidth: 20 },
  { id: 'date', label: 'Date', maxWidth: 100 },
  { id: 'fundName', label: 'Fund name', minWidth: 200 },
  {
    id: 'growth', label: 'Amount Invested', maxWidth: 100,
  },
  {
    id: 'valueOfInvestment', label: 'Value of investment', maxWidth: 200,
  },
  {
    id: 'growth', label: 'Investment Growth', maxWidth: 100,
  },
  { id: 'more', label: '', maxWidth: 50 },
];

const TablePaper = styled(Paper)(({ theme }) => ({
  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.3)"
}));

const Row = ({ row, openSingleGraph }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [tab, setTab] = useState('NAV / Performance Info')
  const [isOpen, setIsOpen] = useState(false);
  const formatePerformance = {};
_.map(row.performance, (value, key) => {
    if (row.performanceType == "monthly") {
      let parsedDate = moment(key, 'MMMMYYYY');
      let formattedDate = parsedDate.format('MM/YYYY');
      formatePerformance[formattedDate] = value;
    } else {
      let parsedDate = moment(key, 'DDMMYYYY');
      let formattedDate = parsedDate.format('DD/MM/YYYY');
      formatePerformance[formattedDate] = value;
    }
  });

  const sortPerformanceByDate = _.sortBy(Object.entries(formatePerformance), ([key]) => {
    if (row.performanceType == "monthly") {
      const date = moment(key, 'MM/YYYY').toDate();
      return date;
    } else {
      const date = moment(key, 'DD/MM/YYYY').toDate();
      return date;
    }
  });
  const convertPerformance = _.fromPairs(sortPerformanceByDate);
  const monthData = _.keys(convertPerformance);
  const performance = _.values(convertPerformance);
  const lastValueOfIndex = _.last(performance);
  const recommendationFormate = moment(row.recommendationDate, 'YYYY/MM/DD');
  const recommedationsData = row.performanceType == "monthly" ? recommendationFormate.format('MM/YYYY') : recommendationFormate.format('DD/MM/YYYY');
  const findIndex = monthData.indexOf(recommedationsData);
  const navValue = row.navAtRecommendationDate
  const growthNav = ((parseFloat(lastValueOfIndex) - parseFloat(row.navAtRecommendationDate)) / parseFloat(row.navAtRecommendationDate)) * 100;
  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };
  let valueOfInvestment = (row.amount * (parseFloat(growthNav)) / 100);

  let initialValue = row.amount;
  let rate = +row.benchmark?.annualRateOfReturn

  const recommendedDate = moment(row.recommendationDate);
  let enddate = moment()
  let diffDays = enddate.diff(recommendedDate, 'days')
  let pfToatalAmount  = initialValue * (1 + (rate / 100)) ** (diffDays / 365);
  let pfDiffGrowth = ((pfToatalAmount - parseFloat(row.amount)) / parseFloat(row.amount)) * 100;


  const differenceNavGroth = ((parseFloat(row?.benchmark?.latestNav) - parseFloat(row?.benchmark?.navAtRecommendationDate)) / parseFloat(row?.benchmark?.navAtRecommendationDate)) * 100;
  let valueOfBenInvestment = (row.amount * (parseFloat(differenceNavGroth)) / 100);
  let totalBenInvestmentValue = parseFloat(row.amount) + parseFloat(valueOfBenInvestment);
  let totalInvestmentValue = parseFloat(row.amount) + parseFloat(valueOfInvestment);

  const sortPerformance = _.sortBy(performance.slice());
  const sortPerformancelowestValue = sortPerformance[0];
  const sortPerformanceHighValue = sortPerformance[sortPerformance.length - 1];
  const differenceSortData = sortPerformanceHighValue - sortPerformancelowestValue;
  const tenPercentOfLowestValue = differenceSortData * 0.1;
  const subtenPercentOfLowestValue = sortPerformancelowestValue - tenPercentOfLowestValue;

  let percentageMfIncrease = (( growthNav - differenceNavGroth)/differenceNavGroth)* 100;
  let percentagePfIncrease = ((growthNav - pfDiffGrowth)/pfDiffGrowth )* 100;

  const beatBy = row?.benchmark?.isMfBenchmark ? percentageMfIncrease : percentagePfIncrease
   const benchMark = row.benchmark && row.benchmark !== null;

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={()=> setIsOpen(!isOpen)}>
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center">{row.recommendationDate}</TableCell>
        <TableCell align="center">{row.fund.name}</TableCell>
        <TableCell align="center">{row.amount}</TableCell>
        {
          isNaN(totalInvestmentValue) ? (
            <TableCell align="center">-</TableCell>
          ) : (
            <TableCell align="center">{totalInvestmentValue.toFixed(2)}</TableCell>
          )
        }
        {
          isNaN(growthNav) ? (
            <TableCell align="center">-</TableCell>
          ) :
            (
              <TableCell align="center">{`${growthNav.toFixed(2)}%`} </TableCell>
            )
        }
        <TableCell align="center">
          <MoreVertIcon style={{ cursor: 'pointer' }} onClick={(event) => {
            setMenuOpen(!menuOpen)
            setAnchorEl(event.currentTarget)
          }} />
          <Popover
            open={menuOpen}
            anchorEl={anchorEl}
            onClose={() => setMenuOpen(false)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}>
            <MenuList>
              <MenuItem onClick={() => {
                setMenuOpen(false)
              }}>
                <ListItemText>Delete recommendation</ListItemText>
              </MenuItem>
            </MenuList>
          </Popover>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: '#eee', }} colSpan={7}>
          <Collapse in={openSingleGraph == row.uuid ? true : isOpen} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Tabs value={tab} onChange={handleTabChange}>
                  {['NAV / Performance Info', 'Benchmark Info'].map((rowTab) => (
                    <Tab key={rowTab} value={rowTab} label={rowTab} />
                  ))}
                </Tabs>
                {tab === 'NAV / Performance Info' &&
                  <Grid className={styles['collapsed-cell-container']} container spacing={2}>
                    <Grid item xs={3}>
                      <Table>
                        <TableRow>
                          <TableCell> NAV on recommendation date </TableCell>
                          <TableCell>{navValue} </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell> Latest NAV` </TableCell>
                          <TableCell> {lastValueOfIndex} </TableCell>
                        </TableRow>
                        <Box sx={{ m: 4 }} />
                        <TableRow>
                        <TableCell style={{ backgroundColor: 'lightgray' }} colSpan={6}>
                          <Typography style={{ color: '#000', textAlign: 'center', fontWeight: '600' }} variant="body1">
                            {isNaN(growthNav) ? 'Total Gain : N/A' : `Total Gain ${growthNav.toFixed(2)}%`}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </Table>
                  </Grid>
                  <Grid item xs={9}>
                    <LineChart
                      height={300}
                      xAxis={[{
                        scaleType: 'point', data: [...monthData],
                        tickLabelStyle: {
                          fontSize: 10,
                          fill: '#333',
                          color: "#000",
                          angle: 40,
                          textAnchor: "start",
                          }
                       }]}
                        yAxis={[{ min: subtenPercentOfLowestValue, max: sortPerformance[sortPerformance.length - 1], data: performance }]}
                        series={[
                          { data: performance }]}
                      >
                        {findIndex < 10 ?
                          <ChartsReferenceLine
                            x={recommedationsData}
                          label="Recommendation date"
                          lineStyle={{ stroke: 'red' }}
                          labelStyle={{ fontSize: 12 }}
                          labelAlign="start"
                        />
                        :
                        <ChartsReferenceLine
                          x={recommedationsData}
                          label="Recommendation date"
                          lineStyle={{ stroke: 'red' }}
                          labelStyle={{ textAnchor: 'end', fontSize: 12 }}
                          labelAlign="start"
                        />
                      }
                    </LineChart>
                  </Grid>
                  </Grid>
                }
                {tab === 'Benchmark Info' &&
                  <Grid style={{ marginTop: 12 }} container spacing={2}>
             <Grid item xs={12}> 
                  {benchMark ?
                       <Table>
                        <TableRow variant="head">
                          <TableCell />
                      <TableCell className={styles['table-header-cell']}>Benchmark</TableCell>
                      <TableCell className={styles['table-header-cell']}>Recommendation</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={styles['table-header-cell']}> Name </TableCell>
                      <TableCell> {row.benchmark?.benchmarkName} </TableCell>
                      <TableCell> {row.fund.name} </TableCell>
                    </TableRow>
                    <TableRow>
                          <TableCell className={styles['table-header-cell']}> Initial Investment </TableCell>
                          <TableCell> {row.amount} </TableCell>
                          <TableCell> {row.amount} </TableCell>
                        </TableRow>
                    <TableRow>
                      <TableCell className={styles['table-header-cell']}> Current Investment Value </TableCell>
                      <TableCell> {row?.benchmark?.isMfBenchmark  ? totalBenInvestmentValue.toFixed(2) : pfToatalAmount.toFixed(2)}</TableCell>
                      <TableCell> {totalInvestmentValue.toFixed(2)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className={styles['table-header-cell']}> Gain % </TableCell>
                      <TableCell> {row?.benchmark?.isMfBenchmark ? differenceNavGroth.toFixed(2) : pfDiffGrowth.toFixed(2)}%</TableCell>
                      <TableCell> {`${growthNav.toFixed(2)}%`} </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ backgroundColor: '#666' }} colSpan={3}>
                        { beatBy < 0 ?  <Typography style={{ color: '#fff', fontWeight: '600' }} variant="body1">
                          Recommendation under performed the benchmark by {beatBy.toFixed(2)}%</Typography>
                          :
                          <Typography style={{ color: '#fff', fontWeight: '600' }} variant="body1">
                            Recommendation over performed the benchmark by {beatBy.toFixed(2)}%
                            </Typography>
                        }
                          </TableCell>
                        </TableRow>
                        </Table> 
                          :
                          <>
                           <StackedLineChartIcon style={{ color: red[800], fontSize: '15px', marginLeft:'40%'}} />
                            <span style={{ color: grey[600], fontWeight: 500 }}> Data unavailable </span>
                          </>
                          }
                    </Grid>
                  </Grid>
                }
              </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>)
}
const RecommendationTable = ({ recommendations,openSingleGraph }) => {

  return (
    <>
      <div className={styles["table-div"]}>
        <TableContainer
          component={TablePaper}
          style={{ maxHeight: "700px", overflow: "auto", marginTop: 10 }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
               {_.map(columns, (column) => (
                  <TableCell
                    align="center"
                    variant="head"
                    style={{ minWidth: column.minWidth, maxWidth: column.maxWidth }}
                    className={styles['table-header-cell']}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {recommendations?.map((row, index) => (
                <Row openSingleGraph={openSingleGraph}  key={index} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {recommendations.length === 0 && <div className={styles["empty-table-content"]}>
          <Typography>You haven’t added any recommedations yet for this lead.</Typography>
        </div>}
      </div>
    </>
  );
};

export default RecommendationTable;
