import React, { Component } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import styles from "./_create-lead.module.scss";
import {
  Typography, Box, ToggleButtonGroup,
  Button, Stack, ToggleButton, Divider,
  Dialog, DialogContent, Grid, IconButton,
  Container, Autocomplete, AccordionSummary,
  TextField, Accordion, AccordionDetails,
  DialogTitle, CircularProgress, List,
  ListItem, ListItemText, ListItemIcon, DialogActions
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { toast } from "react-toastify";
import EventBus from "eventing-bus";
import { ACTION_TYPES } from "src/constants";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import AddRecommendationDialog from "../add-recommendation-dialog";
import * as Yup from "yup";

class CreateLead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leads: [],
      closedNewLead: false,
      addRecommendationButtonError: false,
      isLoading: false,
      numberOfLeads: 1
    };
  }

  componentDidMount() {
    this.createLeadSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_CREATE_LEADS,
      () => {
        this.setState({ isLoading: true });
      }
    );

    this.createLeadSuccessSubscription = EventBus.on(
      ACTION_TYPES.CREATE_LEADS_SUCCESS,
      () => {
        toast.success("Lead creation succeeded!", "success");
        this.setState({ isLoading: false },
          () => this.props.onClose && this.props.onClose()
        )
      }
    );

    this.createLeadFailureSubscription = EventBus.on(
      ACTION_TYPES.CREATE_LEADS_FAILURE,
      () => this.setState({ isLoading: false })
    );

    this.requestRiskProfilesSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_RISK_PROFILES,
      () => {
        this.setState({ isRiskProfilesLoading: true });
      }
    );

    this.requestRiskProfilesSuccessSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_RISK_PROFILES_SUCCESS,
      () => { this.setState({ isRiskProfilesLoading: false } )
      }
    );

    this.requestRiskProfilesFailureSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_RISK_PROFILES_FAILURE,
      () => this.setState({ isRiskProfilesLoading: false })
    );

    this.props.requestRiskProfiles()
  }

  componentWillUnmount() {
    this.createLeadSubscription()
    this.createLeadSuccessSubscription()
    this.createLeadFailureSubscription()

    this.requestRiskProfilesSubscription()
    this.requestRiskProfilesSuccessSubscription()
    this.requestRiskProfilesFailureSubscription()
  }

  handleNumberOfLeads = (numberOfLeads) => {
    this.setState({numberOfLeads})
  }

  createNewLeadAt = (index) => {
    const newLeads = [...this.state.leads]
    newLeads[index] ||= {}

    return newLeads
  }

  handleNameChanged = (index, newValue) => {
    const newLeads = this.createNewLeadAt(index)
    newLeads[index].full_name = newValue

    this.setState({ leads: newLeads })
  }

  handleEmailChanged = (index, newValue) => {
    const newLeads = this.createNewLeadAt(index)
    newLeads[index].email = newValue

    this.setState({ leads: newLeads })
  }

  handlePhoneNumberChanged = (index, newValue) => {
    const newLeads = this.createNewLeadAt(index)
    newLeads[index].phone_number = newValue

    this.setState({ leads: newLeads })
  }

  handleRiskProfileSelection = (index, newValue) => {
    const newLeads = this.createNewLeadAt(index)
    newLeads[index].risk_profile_uuid = newValue?.uuid

    this.setState({ leads: newLeads })
  }

  handleRecommendationDialogOpen = (index) => {
    const newLeads = this.createNewLeadAt(index)
    newLeads[index].openRecommendationDialog = true

    this.setState({ leads: newLeads })
  };

  handleSaveRecommendations = (index, recommendations) => {
    const newLeads = this.createNewLeadAt(index)
    newLeads[index].lead_recommendations_parts = recommendations
  
    this.setState({ leads: newLeads })
  }

  generateApiRequest = () => {
    return _.map(this.state.leads, (lead) => ({
      full_name: lead.full_name,
      email: lead.email,
      risk_profile_uuid: lead.risk_profile_uuid,
      phone_number: lead.phone_number,
      lead_recommendations_parts: _.map(lead.lead_recommendations_parts || [], (part) => ({
        investment_type: part.investmentType,
        benchmark_uuid: part.benchmark?.uuid,
        recommendation_date: part.recommendationDate.format("DD/MM/YYYY"),
        fund_code: part.fund?.code,
        amount: part.amount,
        reminders: part.reminders && part.reminders[0] ? [{
          frequency: part.reminders[0].frequency,
          delivery_time: part.reminders[0].deliveryTime.format("DD/MM/YYYY HH:mm"),
        }] : []
      }))
    }))
  }

  handleSave = () => {
    this.props.requestCreateLead({
      leads: {
        parts: this.generateApiRequest()
      }
    });
  };

  handleClosedNewLead = (value) => {
    this.setState({ closedNewLead: value });
  };

  handleRecommendationDialogClose = (index) => {
    const newLeads = this.createNewLeadAt(index)
    newLeads[index].openRecommendationDialog = false

    this.setState({ leads: newLeads })
  };

  render() {
    const { isOpen, onClose, riskProfiles } = this.props;
    const { name, email, mobileNumber, addRecommendationButtonError, numberOfLeads } = this.state;

    return (
      <Dialog open={isOpen}
        onClose={onClose}
        fullWidth={true}
        keepMounted={false}
        maxWidth="sm"
      >
        <DialogTitle>Add New Leads</DialogTitle>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box className={styles["box-field"]}>
            <Typography style={{fontWeight: '600'}} variant="caption"> Number of leads: </Typography>
            <ToggleButtonGroup
              value={numberOfLeads}
              exclusive
              onChange={(event) => this.handleNumberOfLeads(event.target.value)}
            >
              { _.times(10, (index) => (
                <ToggleButton value={index + 1}> {index + 1} </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Box>

          <Stack divider={<Divider style={{marginTop: 16, marginBottom: 16}} flexItem />}>
            {_.times(numberOfLeads, (index) => {
              const recommendations = this.state.leads[index]?.lead_recommendations_parts

              return (
                <div key={index} sx={{ marginBottom: 2 }}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "Helvetica Neue",
                      fontWeight: 500,
                    }}
                    >{`Lead ${index + 1}`}
                  </Typography>
                  <Grid
                    container
                    rowSpacing={2.5}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    style={{marginTop: '2px'}}
                  >
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        label="Name"
                        sizeType="medium"
                        onChange={(event) => this.handleNameChanged(index, event.target.value) }
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        type="text"
                        label="Email ID"
                        sizeType="medium"
                        onChange={(event) => this.handleEmailChanged(index, event.target.value) }
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        type="text"
                        label="Phone number (optional)"
                        sizeType="medium"
                        onChange={(event) => this.handlePhoneNumberChanged(index, event.target.value) }
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <Autocomplete
                        fullWidth
                        options={riskProfiles}
                        getOptionLabel={(option) => option.text}
                        renderInput={(params) => (
                          <TextField {...params} label="Risk Profile (optional)"
                          onChange={(event, newValue) =>
                            this.handleRiskProfileSelection(
                              index,
                              newValue
                            )
                          } 
                          />
                        )}
                      />
                    </Grid>

                    {
                      recommendations &&
                      <Grid item xs={12}>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ArrowDropDownIcon />}
                            aria-controls="panel1-content"
                          >
                            <Typography>Recommendations for {this.state.leads[index].full_name || `Lead ${index + 1}`}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <List dense={true}>
                              {_.map(this.state.leads[index].lead_recommendations_parts, (part) => {
                                return(
                                  <ListItem>
                                    <ListItemIcon>
                                      <ArrowRightAltIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={part.fund.name}
                                      secondary={`Rs. ${part.amount} on ${part.recommendationDate?.format("DD/MM/YYYY")}`}
                                    />
                                  </ListItem>
                                )
                              })}
                            </List>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    }

                    <Grid item xs={12}>
                      <Button
                        variant="outlined"
                        className={styles["button"]}
                        startIcon={recommendations ? <EditIcon /> : <AddIcon />}
                        disabled={this.state.isLoading}
                        onClick={() => {
                          this.handleRecommendationDialogOpen(index);
                        }}
                      >
                        {recommendations ? 'Edit' : 'Add'} recommendations
                      </Button>
                    </Grid>

                    {
                      this.state.leads[index]?.openRecommendationDialog &&
                        <AddRecommendationDialog
                          newLead
                          name={this.state.leads[index]?.full_name || `Lead ${index + 1}` }
                          isOpenDialog={this.state.leads[index]?.openRecommendationDialog}
                          onClose={() => this.handleRecommendationDialogClose(index)}
                          onSave={(recommendations) => {
                            this.handleSaveRecommendations(index, recommendations)
                            this.handleRecommendationDialogClose(index)
                          }}
                          currentRecommendations={recommendations}
                        />
                    }
                  </Grid>
                </div>
              )}
            )}
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button
            variant="contained"
            style={{margin: 8}}
            disabled={this.state.isLoading}
            startIcon={this.state.isLoading ? <CircularProgress size={25} color="inherit" /> : <SaveIcon />}
            onClick={this.handleSave}
          >
            Save leads
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default CreateLead;
