import { takeEvery, call, put } from "redux-saga/effects";
import { ACTION_TYPES } from "src/constants";
import {
  adminDepositsList,
  createAdminDeposit,
  adminShowDeposit,
  checkAdminPan,
  checkAdminFolioList
} from "src/redux/api";
import {
  requestAdminDepositListSuccess,
  requestAdminDepositListFailed,
  requestAdminCreateDepositSuccess,
  requestAdminCreateDepositFailed,
  requestAdminShowDepositSuccess,
  requestAdminShowDepositFailed,
  requestAdminCheckPanSuccess,
  requestAdminCheckPanFailed,
  requestAdminFolioListSuccess,
  requestAdminFolioListFailed,
} from "src/redux/actions";

export function* watchAdminDepositsRequests() {
  yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_DEPOSITS, requestAdminDepositList);
  yield takeEvery(
    ACTION_TYPES.REQUEST_ADMIN_CREATE_DEPOSITS,
    requestAdminCreateDeposit
  );
  yield takeEvery(
    ACTION_TYPES.REQUEST_ADMIN_SHOW_DEPOSIT,
    requestAdminShowDeposit
  );
  yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_CHECK_PAN, requestAdminCheckPan);
  yield takeEvery(ACTION_TYPES.REQUEST_ADMIN_FOLIO_LIST, requestAdminFolioList);
}

function* requestAdminDepositList(action) {
  try {
    const deposits = yield call(adminDepositsList, action.payload);
    yield put(requestAdminDepositListSuccess(deposits));
  } catch (e) {
    yield put(requestAdminDepositListFailed(e));
  }
}

function* requestAdminCreateDeposit(action) {
  try {
    const deposits = yield call(createAdminDeposit, action.payload);
    yield put(requestAdminCreateDepositSuccess(deposits));
  } catch (e) {
    yield put(requestAdminCreateDepositFailed(e));
  }
}

function* requestAdminShowDeposit(action) {
  try {
    const deposit = yield call(adminShowDeposit, action.payload);
    yield put(requestAdminShowDepositSuccess(deposit));
  } catch (e) {
    yield put(requestAdminShowDepositFailed(e));
  }
}

function* requestAdminCheckPan(action) {
  try {
    const pandata = yield call(checkAdminPan, action.payload);
    yield put(requestAdminCheckPanSuccess(pandata));
  } catch (e) {
    yield put(requestAdminCheckPanFailed(e));
  }
}


function* requestAdminFolioList(action) {
  try {
    const folioList = yield call(checkAdminFolioList, action.payload);
    yield put(requestAdminFolioListSuccess(folioList));
  } catch (e) {
    yield put(requestAdminFolioListFailed(e));
  }
}
