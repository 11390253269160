import { connect } from "react-redux";
import {
  requestAdminShowGenericLink,
  requestLandingPages,
  requestAdminUpdateGenericLink,
} from "src/redux/actions";
import ReadModal from "./read-modal";

const mapStateToProps = (state) => ({
  genericLink: state.adminGenericLinks?.showGenericLink,
  landingPages: state.landingPages,
});

export default connect(mapStateToProps, {
  requestAdminShowGenericLink,
  requestLandingPages,
  requestAdminUpdateGenericLink,
})(ReadModal);
