import React from "react";
import cx from "classnames";

import styles from "./_label.module.scss";

export default function Label(props) {
  return (
    <label
      {...props}
      className={cx(styles.label, props.className, {
        [styles[props.sizeType]]: props?.sizeType,
      })}
    >
      {props.children}
    </label>
  );
}
