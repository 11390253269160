import { connect } from 'react-redux';
import CreateMessageTemplate from './create-message-template';
import {
  requestCreateMessageTemplate
} from "src/redux/actions";

const mapStateToProps = (state) =>  { 
  return {
    messageTemplateVariables: state.messageTemplateVariables,
  }
}

export default connect(mapStateToProps, { 
  requestCreateMessageTemplate
})(CreateMessageTemplate);