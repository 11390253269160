import { takeEvery, call, put } from "redux-saga/effects";
import { ACTION_TYPES } from "src/constants";
import { getNoteKeywords, createLeadNotes } from "src/redux/api";
import {
  requestNoteKeywordsSuccess,
  requestNoteKeywordsFailed,
  requestCreateNotesSuccess,
  requestCreateNotesFailed,
} from "src/redux/actions";

export function* watchLeadNotesApi() {
  yield takeEvery(ACTION_TYPES.REQUEST_NOTE_KEYWORDS, requestNoteKeywords);
  yield takeEvery(ACTION_TYPES.REQUEST_CREATE_NOTES, requestCreateLeadNotes);
}

function* requestNoteKeywords(action) {
  try {
    const data = yield call(getNoteKeywords, action.payload);
    yield put(requestNoteKeywordsSuccess(data));
  } catch (e) {
    yield put(requestNoteKeywordsFailed(e));
  }
}

function* requestCreateLeadNotes(action) {
  try {
    const data = yield call(createLeadNotes, action.payload);
    yield put(requestCreateNotesSuccess(data));
  } catch (e) {
    yield put(requestCreateNotesFailed(e));
  }
}