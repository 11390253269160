import { persistCombineReducers } from "redux-persist";
import storageSession from 'redux-persist/lib/storage/session'
import AdminCredentialReducer from "./admin-credentials.reducer";
import AdminDepositsReducer from "./admin-deposits.reducer";
import AdminSipReducer from "./admin-sip.reducer";
import AdminWithdrawalsReducer from "./admin-withdrawals.reducer";
import AdminGenericLinksReducer from "./admin-generic-links.reducer";
import AdminAmcsReducer from "./admin-amcs.reducer";
import AdminFundsReducer from "./admin-funds.reducer";
import AdminWebhooksReducer from "./admin-webhook.reducer";
import AdminReducer from "./admin.reducer";
import AdminBatchUploadReducer from "./admin-batch-upload.reducer";
import BatchUploadsReducer from "./batch-uploads.reducer";
import LandingPagesReducer from "./admin-landing-pages.reducer";
import WhatsappTemplatesReducer from './whatsapp-templates.reducer';

import LeadsReducer from "./leads.reducer";
import BenchmarksReducer from "./benchmarks.reducer";
import LeadNotesReducer from "./lead-notes.reducer";
import NoteKeywordsReducer from "./note-keywords.reducer";
import RiskProfilesReducer from "./risk-profiles.reducer";
import MessageTemplatesReducer from "./message-templates.reducer";
import MessageTemplateVariablesReducer from "./message-template-variables.reducer";

import EncryptionTransform from "../middlewares/encryption.transformer"

const persistConfig = {
  key: "root",
  storage: storageSession,
  transforms: [EncryptionTransform],
  blacklist: ['adminCredentials'],
};

const rootReducer = persistCombineReducers(persistConfig, {
  adminCredentials: AdminCredentialReducer,
  adminDeposits: AdminDepositsReducer,
  adminSip: AdminSipReducer,
  adminWithdrawals: AdminWithdrawalsReducer,
  adminGenericLinks: AdminGenericLinksReducer,
  adminAmcs: AdminAmcsReducer,
  adminFunds: AdminFundsReducer,
  adminWebhooks: AdminWebhooksReducer,
  admin: AdminReducer,
  adminIsBatchUploaded: AdminBatchUploadReducer,
  batchUploads: BatchUploadsReducer,
  landingPages: LandingPagesReducer,
  whatsappTemplates: WhatsappTemplatesReducer,
  leads: LeadsReducer,
  benchmarks: BenchmarksReducer,
  leadNotes: LeadNotesReducer,
  noteKeywords: NoteKeywordsReducer,
  riskProfiles: RiskProfilesReducer,
  messageTemplates: MessageTemplatesReducer,
  messageTemplateVariables: MessageTemplateVariablesReducer,
});

export default rootReducer;
