import { ApiRequest } from "src/helpers";
import { API_END_POINTS } from "src/constants";

export function adminDepositsList({ page, checkout_type }) {
  return ApiRequest.fetchAdmin({
    method: "get",
    url: `${API_END_POINTS.ADMIN_DEPOSITS_LIST}?page=${page}&checkout_type=${checkout_type}`,
  }).then((response) => response.data);
}

export function createAdminDeposit(data) {
  return ApiRequest.fetchAdmin({
    method: "post",
    url: API_END_POINTS.ADMIN_CREATE_DEPOSIT,
    data,
  }).then((response) => response.data);
}

export function adminShowDeposit({ uuid }) {
  return ApiRequest.fetchAdmin({
    method: "get",
    url: `${API_END_POINTS.ADMIN_SHOW_DEPOSIT}/${uuid}`,
  }).then((response) => response.data);
}

export function checkAdminPan(data) {
  return ApiRequest.fetchAdmin({
    method: "post",
    url: API_END_POINTS.ADMIN_CHECK_PAN,
    data,
  }).then((response) => response.data);
}

export function checkAdminFolioList(data) {
  return ApiRequest.fetchAdmin({
    method: "post",
    url: API_END_POINTS.ADMIN_FETCH_FOLIO,
    data,
  }).then((response) => response.data);
}
