import React, { Component } from 'react';
import Header from "src/components/header";
import { withRouter } from "react-router-dom";
import { Form, Formik } from "formik";
import Paper from '@mui/material/Paper';
import { Fab } from "@mui/material";
import styles from "./reminder-dashboard.module.scss";
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import SendIcon from '@mui/icons-material/Send';
import CreateReminderDialog from './create-reminder'
import SendReminderDialog from './send-reminder-dialog'
import ReminderDashboardTable from './reminder-dashboard-table/reminder-dashboard-table';

class ReminderDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      openSendReminder: false
    };  
  }

  handleClickOpen = () => {
    this.setState({ open: !this.state.open });
  };

  handleClose = () => {
    this.setState({ open: !this.state.open });
  };

  handleClickOpenSendReminder = () => {
    this.setState({ openSendReminder: true });
  }

  handleCloseSendReminder = () => {
    this.setState({ openSendReminder: false });
  }

  render() {
    const { reminders } = this.props
    const title = "Reminders Management";
    return (
      <div>
        <Header isCrm={true} title={title} />
        <ReminderDashboardTable
          data={reminders}
          handlePaginationChange={() => {}}
          totalItems={3}
        />

        <div className={styles["footer-btn"]}>
          <div>
            <Fab variant="extended" color="primary" onClick={this.handleClickOpenSendReminder} style={{marginRight: 8}}>
              <SendIcon sx={{ mr: 1 }} />
              Send a reminder
            </Fab>
            <Fab variant="extended" onClick={this.handleClickOpen} style={{marginRight: 8}}>
              <NotificationAddIcon sx={{ mr: 1 }} />
              Add Reminder
            </Fab>
            {
              this.state.open &&
                <CreateReminderDialog
                  isOpen={this.state.open}
                  onClose={this.handleClose}
                />
            }
            {
              this.state.openSendReminder &&
                <SendReminderDialog
                  isOpen={this.state.openSendReminder}
                  onClose={this.handleCloseSendReminder}
                />
            }
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(ReminderDashboard);
