import React, { Component } from 'react';
import Container from '@mui/material/Container';
import styles from './_screen.module.scss';

class Screen extends Component {
  render() {
    const { children, class: className } = this.props;

    return (
      <div className={`${styles['screen']} ${className || ''}`}> 
        <div className={`screen-animate ${styles['screen-container']}`}>
          <Container className={this.props.contentClassName}>{children}</Container>
        </div>
      </div>
    );
  }
}

export default Screen;
