import React, { Component } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter as Router, Switch, Route, useLocation, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { store, persistor } from "./redux/store";

import { AdminProtectedRoute, AdminAuthRoute, ErrorFallback } from "./components";
import { ApiRequest } from "src/helpers";
import { NotFound, Admin, AdminLogin, AdminLogout } from "./screens";
import { ACTION_TYPES } from "src/constants";
import "./scss/_styles.scss";

const ENVIRONMENT = process.env.REACT_APP_ENV || "development";

class App extends Component {
  constructor(props) {
    super(props);

    this.store = store;
    this.persistor = persistor;
    global.store = this.store;
  }

  componentDidMount() {
    window.addEventListener("beforeunload", (ev) => {
      if(this.store.getState().adminCredentials?.token) {
        ev.preventDefault();
      }
    });

    window.addEventListener(
      "pagehide",
      (event) => {
        event.preventDefault();
        ApiRequest.forceLogout();
        this.store.dispatch({ type: ACTION_TYPES.REQUEST_ADMIN_LOGOUT });
      },
      false,
    );
  }

  render() {
    return (
      <Provider store={this.store}>
        <PersistGate persistor={this.persistor}>
          <ToastContainer />
          <div className="savvy-main">
            <Router basename="/backoffice">
              <Switch>
                <Route path="*">
                  <AnimatedSwitch />
                </Route>
              </Switch>
            </Router>
          </div>
        </PersistGate>
      </Provider>
    );
  }
}

function AnimatedSwitch() {
  const location = useLocation();
  const history = useHistory();

  return (
    <Switch location={location}>
      <Route exact path="/" render={() => history.push("/admin")} />
      <AdminAuthRoute path="/admin/sign-in" component={AdminLogin} />
      <AdminProtectedRoute path="/admin/logout" component={AdminLogout} />
      <AdminProtectedRoute path="/admin" component={Admin} />
      <Route path="*" component={NotFound} />
    </Switch>
  );
}

export default App;
