import React, { Component } from "react";
import EventBus from "eventing-bus";
import { ACTION_TYPES } from "src/constants";
import { Loader, Header } from "src/components";
import { Form, Formik } from "formik";
import moment from "moment";
import { TextInputOne, ButtonOne } from "src/components/form-inputs";
import { Link, withRouter } from "react-router-dom";
import BatchLinksTable from "./batch-links-table";
import { Container } from "../../../../components";
// import CreateEditOneTimeTransaction from "./create-edit-one-time-transaction";
import styles from "./_batch-links.module.scss";

class BatchLinks extends Component {
  state = {
    isLoading: false,
    pageNumber: 1,
    showCreateEditModal: false,
    showGeneratedLinkModal: false,
    search: "",
  };

  componentDidMount() {
    this.requestDepositsSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_BATCH_UPLOAD_LIST,
      () => this.setState({ isLoading: true })
    );
    this.depositsSubscriptionSuccess = EventBus.on(
      ACTION_TYPES.REQUEST_BATCH_UPLOAD_LIST_SUCCESS,
      this.handleBatchUploadsListSuccess
    );
    this.depositsSubscriptionFailure = EventBus.on(
      ACTION_TYPES.REQUEST_BATCH_UPLOAD_LIST_FAILURE,
      () => this.setState({ isLoading: false })
    );

    this.props.requestBatchUploadList({page: this.state.pageNumber});
  }

  componentWillUnmount() {
    this.requestDepositsSubscription();
    this.depositsSubscriptionSuccess();
    this.depositsSubscriptionFailure();
  }

  handleBatchUploadsListSuccess = () => {
    this.setState({ isLoading: false });
  };

  _handlePaginationChange = (pageNumber) => {
    this.setState(
      {
        pageNumber,
      },
      () =>
        this.props.requestBatchUploadList({
          page: pageNumber
        })
    );
  };

  handleSearch = (e) => {
    this.setState({ search: e.target.value.toLowerCase() });
  };

  render() {
    const { isLoading, showCreateEditModal, showGeneratedLinkModal, search } =
      this.state;
    const { batchUploads } = this.props;
    let regex = new RegExp(search, "i");

    return (
      <div className={styles["one-time-transactions"]}>
        <Header title="Bulk Upload Requests" />
        <Container>
          <div className={styles["search-box"]}>
            <Formik initialValues={{ search: "" }}>
              <Form>
                <div className={styles["search-input-box"]}>
                  <TextInputOne
                    name="search"
                    id="search"
                    type="text"
                    value={search}
                    placeholder="Search by upload name..."
                    onChange={this.handleSearch}
                  />
                  <div className={styles["search-icon"]}>
                    <img src="/img/search.svg" alt="search" />
                  </div>
                </div>
              </Form>
            </Formik>
          </div>

          {<BatchLinksTable
            data={batchUploads || []}
            handlePaginationChange={this._handlePaginationChange}
            totalItems={batchUploads?.count || 0}
          />}

          <div className={styles["divider"]} />
          <div className={styles["footer"]}>
            <Link to={"/admin/batch-link-create"}>
              <ButtonOne>
                <img
                  src="/img/plusnew.svg"
                  alt="plus"
                  className={styles["plus-btn"]}
                />
                <div>Upload Batch File</div>
              </ButtonOne>
            </Link>
          </div>
        </Container>
        <Loader loading={isLoading} />
      </div>
    );
  }
}

export default withRouter(BatchLinks);
