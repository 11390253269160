import { connect } from 'react-redux';
import AddRecommendationDialog from './add-recommendation-dialog';
import {
  requestCreateLeadRecommendations,
  requestAdminFunds,
  requestLead,
  requestBenchmarks,
  requestCreateLead,
} from "src/redux/actions";

const mapStateToProps = (state) =>  {
  return {
    adminAmcs: state.adminAmcs,
    adminFunds: state.adminFunds,
    benchmarks: state.benchmarks,
  }
}

export default connect(mapStateToProps, {
  requestCreateLeadRecommendations, requestAdminFunds, requestLead,
  requestBenchmarks, requestCreateLead })(AddRecommendationDialog);