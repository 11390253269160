import { takeEvery, call, put } from "redux-saga/effects";
import { ACTION_TYPES } from "src/constants";
import { createLeadReminder, updateLeadReminder } from "src/redux/api";
import {
  requestCreateLeadReminderSuccess,
  requestCreateLeadReminderFailed,
  requestUpdateLeadReminderSuccess,
  requestUpdateLeadReminderFailed,
} from "src/redux/actions";

export function* watchLeadRemindersApi() {
  yield takeEvery(ACTION_TYPES.REQUEST_CREATE_LEAD_REMINDER, requestCreateLeadReminder);
  yield takeEvery(ACTION_TYPES.REQUEST_UPDATE_LEAD_REMINDER, requestUpdateLeadReminder);
}

function* requestCreateLeadReminder(action) {
  try {
    const data = yield call(createLeadReminder, action.payload);
    yield put(requestCreateLeadReminderSuccess(data));
  } catch (e) {
    yield put(requestCreateLeadReminderFailed(e));
  }
}

function* requestUpdateLeadReminder(action) {
  try {
    const data = yield call(updateLeadReminder, action.payload);
    yield put(requestUpdateLeadReminderSuccess(data));
  } catch (e) {
    yield put(requestUpdateLeadReminderFailed(e));
  }
}