import { ACTION_TYPES } from "src/constants";
import { INITIAL_STATE } from "../store";

export default function NoteKeywordsReducer(state = INITIAL_STATE.noteKeywords, action) {
  switch (action.type) {

    case ACTION_TYPES.REQUEST_NOTE_KEYWORDS_SUCCESS: {
      return action.payload
    }

    case ACTION_TYPES.REQUEST_ADMIN_LOGOUT:
    case ACTION_TYPES.ADMIN_LOGOUT_SUCCESS:
      return INITIAL_STATE.noteKeywords;

    default:
      return state;
  }
}