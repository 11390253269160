import { ACTION_TYPES } from "src/constants";

/** admin dashboard withdrawals */

export function requestAdminWithdrawalsList(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_WITHDRAWALS,
    payload,
  };
}

export function requestAdminWithdrawalsListSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_WITHDRAWALS_SUCCESS,
    payload,
  };
}

export function requestAdminWithdrawalsListFailed(payload) {
  return {
    type: ACTION_TYPES.ADMIN_WITHDRAWALS_FAILED,
    payload,
  };
}

// export function requestAdminCreateSip(payload) {
//   return {
//     type: ACTION_TYPES.REQUEST_ADMIN_CREATE_SIP,
//     payload,
//   };
// }

// export function requestAdminCreateSipSuccess(payload) {
//   return {
//     type: ACTION_TYPES.ADMIN_CREATE_SIP_SUCCESS,
//     payload,
//   };
// }

// export function requestAdminCreateSipFailed(payload) {
//   return {
//     type: ACTION_TYPES.ADMIN_CREATE_SIP_FAILED,
//     payload,
//   };
// }

// export function requestAdminCreateSipClear(payload) {
//   return {
//     type: ACTION_TYPES.ADMIN_CREATE_SIP_CLEAR,
//     payload,
//   };
// }

// export function requestAdminShowSip(payload) {
//   return {
//     type: ACTION_TYPES.REQUEST_ADMIN_SHOW_SIP,
//     payload,
//   };
// }

// export function requestAdminShowSipSuccess(payload) {
//   return {
//     type: ACTION_TYPES.ADMIN_SHOW_SIP_SUCCESS,
//     payload,
//   };
// }

// export function requestAdminShowSipFailed(payload) {
//   return {
//     type: ACTION_TYPES.ADMIN_SHOW_SIP_FAILED,
//     payload,
//   };
// }
