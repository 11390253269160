import { ACTION_TYPES } from "src/constants";
import { INITIAL_STATE } from "../store";
import _ from 'lodash';

export default function WhatsappTemplatesReducer(
  state = INITIAL_STATE.whatsappTemplates,
  action
) {
  switch (action.type) {
    case ACTION_TYPES.REQUEST_WHATSAPP_TEMPLATES_LIST_SUCCESS:{
      return [...action.payload.whatsappTemplates];
    }

    case ACTION_TYPES.REQUEST_WHATSAPP_TEMPLATES_LIST_FAILURE:
      return INITIAL_STATE.whatsappTemplates;

    case ACTION_TYPES.REQUEST_ADMIN_LOGIN:
    case ACTION_TYPES.REQUEST_ADMIN_LOGOUT:
    case ACTION_TYPES.ADMIN_LOGOUT_SUCCESS:
      return INITIAL_STATE.whatsappTemplates;

    default:
      return state;
  }
}
