import { takeEvery, call, put } from "redux-saga/effects";
import { ACTION_TYPES } from "src/constants";
import { getLeads } from "src/redux/api";
import {
  leadSuccess,
  leadFailure,
} from "src/redux/actions";

export function* watchLeadApi() {
  yield takeEvery(ACTION_TYPES.REQUEST_LEAD, requestLeads);
}

function* requestLeads(action) {
  try {
    const leadData = yield call(getLeads, action.payload);
    yield put(leadSuccess(leadData));
  } catch (e) {
    yield put(leadFailure(e));
  }
}