import { connect } from 'react-redux';
import CreateReminder from './create-reminder';
import {
  requestCreateLeadReminder,
  requestMessageTemplates,
} from "src/redux/actions";

const mapStateToProps = (state) =>  { 
  return {
    leads: state.leads,
    messageTemplates: state.messageTemplates
  }
}

export default connect(mapStateToProps, { 
  requestCreateLeadReminder,
  requestMessageTemplates,
})(CreateReminder);