import {
  Dialog, DialogContent, IconButton,
  TextField, Box, Grid, Button, Typography, CircularProgress,
  Autocomplete, Divider, DialogTitle, DialogActions,
} from "@mui/material";
import { Convert } from "src/helpers";
import EventBus from "eventing-bus";
import { ACTION_TYPES } from "src/constants";
import { deepOrange } from '@mui/material/colors';
import React, { Component } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import dayjs from "dayjs";
import moment from 'moment';
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

class EditReminderDialog extends Component {
  constructor(props) {
    super(props);

    props.reminder.deliveryTime = dayjs(props.reminder.deliveryTime, "DD/MM/YYYY HH-mm-ss")

    this.state = {
      isLoading: false,
      reminder: props.reminder,
    }
  }

  componentDidMount() {
    this.editReminderSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_UPDATE_LEAD_REMINDER,
      () => {
        this.setState({ isLoading: true });
      }
    );

    this.editReminderSuccessSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_UPDATE_LEAD_REMINDER_SUCCESS,
      () => {
        toast.success("Reminder updation succeeded!", "success");
        this.setState({ isLoading: false }, () => this.onClose())
      }
    );

    this.editRecommendationFailureSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_UPDATE_LEAD_REMINDER_FAILED,
      () => this.setState({ isLoading: false })
    );
  }

  componentWillUnmount() {
    this.editReminderSubscription()
    this.editReminderSuccessSubscription()
    this.editRecommendationFailureSubscription()
  }

  handleReminderFrequencyChange = (newValue) => {
    this.setState({ reminder: {...this.state.reminder, frequency: newValue.value} });
  }

  handleReminderDateChange = (date) => {
    this.setState({ reminder: {...this.state.reminder, deliveryTime: date} });
  }

  generateApiRequest = () => {
    return {
      frequency: this.state.reminder.frequency,
      delivery_time: this.state.reminder.deliveryTime.format("DD/MM/YYYY HH:mm"),
    }
  }

  handleReminderSave = () => {
    this.props.requestUpdateLeadReminder({
      id: this.state.reminder.uuid,
      lead_reminder: this.generateApiRequest()
    });
  };

  onClose = () => {
    this.setState({isLoading: false}, () => this.props.onClose && this.props.onClose())
  }

  render() {
    const { isOpen, onClose } = this.props;
    const { reminder } = this.state;
    const { lead, leadRecommendation } = reminder

    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Dialog
          open={isOpen}
          onClose={onClose}
          fullWidth={true}
          keepMounted={false}
          maxWidth="sm"
        >
          <DialogTitle>
            <div style={{marginRight: 16}}>
              Edit Reminder for {lead?.fullName}: {leadRecommendation?.fund?.name}
            </div>
          </DialogTitle>
          <IconButton
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>

          <DialogContent dividers>
            <Box>
              <Grid
                container
                rowSpacing={2.5}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={6}>
                  <Autocomplete
                    renderInput={(params) => (
                      <TextField {...params} label="Frequency" />
                    )}
                    disabled={!lead}
                    fullWidth
                    options={[{name: 'One-time', value: 'one-time'}, {name: 'Weekly', value: 'weekly'}, {name: 'Monthly', value: 'monthly'}]}
                    value={{name: Convert.toTitleCase(reminder.frequency), value: reminder.frequency}}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, newValue) => {
                      this.handleReminderFrequencyChange(newValue)}
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <DateTimePicker
                    disabled={!lead}
                    sx={{ display: "flex" }}
                    label="Reminder date & time"
                    value={reminder.deliveryTime}
                    onChange={ (date) => this.handleReminderDateChange(date) }
                  />
                  { reminder.deliveryTime && 
                    <Typography variant="caption" style={{fontWeight: '600'}}>
                      Reminder will be delivered { reminder.frequency }
                      { reminder.frequency == 'weekly' && ` on ${dayjs(reminder.deliveryTime, "DD/MM/YYYY HH:mm").format('dddd')}s` }
                      { reminder.frequency == 'monthly' && ` on the ${moment(reminder.deliveryTime, "DD/MM/YYYY HH:mm").format('Do')} of every month` }
                      { reminder.frequency == 'one-time' && ` on ${reminder.deliveryTime}` }
                      {" "}at { dayjs(reminder.deliveryTime, "DD/MM/YYYY HH:mm").format("hh:mm A") }
                    </Typography>
                  }
                </Grid>
              </Grid>
            </Box>
          </DialogContent>

          <DialogActions>
            <Button
              variant="contained"
              style={{margin: 8}}
              disabled={this.state.isLoading}
              startIcon={this.state.isLoading ? <CircularProgress size={25} color="inherit" /> : <SaveIcon />}
              onClick={this.handleReminderSave}
            >
              Save reminder
            </Button>
          </DialogActions>
        </Dialog>
      </LocalizationProvider>
    );
  }
}
export default EditReminderDialog;
