import React, { useMemo } from "react";
import moment from "moment";
import { Badge } from "src/components/theme";
import { DataTable, TextOne, LinkOne } from "../../../../../components";
import styles from "./_api-keys-table.module.scss";

const API_ENDPOINT_URL = process.env.API_ENDPOINT_URL || "https://surface.thesavvyapp.in";

const ApiKeysTable = (props) => {
  const tableStyles = {
    bodyRow: {
      backgroundColor: "#ffffff",
      borderBottomColor: "#ffffff",
    },
    tableHeader: {
      backgroundColor: "#ffffff",
      borderTop: "1px solid #F3F3F3",
      borderBottom: "unset",
    },
  };

  const { totalItems } = props;

  const pageSize = 50;

  const paginationConfig = {
    totalItems,
    pageSize,
    totalPages: Math.ceil(totalItems / pageSize),
  };

  const columns = useMemo(
    () => [
      {
        Header: <TextOne variant="head">Name</TextOne>,
        accessor: "name",
        id: "name",
        disableSortBy: true,
        Cell: ({ value }) => {
          return (
            <>
              <TextOne>{value}</TextOne>
            </>
          );
        },
      },
      {
        Header: <TextOne variant="head">&nbsp;</TextOne>,
        accessor: "key",
        disableSortBy: true,
        Cell: ({ row: { original }, value }) => {
          if (original?.isRevealed) {
            return <TextOne>{value}</TextOne>;
          }
          return (
            <div className={styles["reveal-keys"]}>
              <button>Reveal Test Key</button>
            </div>
          );
        },
      },
      {
        Header: <TextOne variant="head">Last Used</TextOne>,
        accessor: "last_used",
        id: "last_used",
        disableSortBy: true,
        Cell: ({ value }) => {
          return <TextOne>{value}</TextOne>;
        },
      },
      {
        Header: <TextOne variant="head">Created</TextOne>,
        accessor: "created",
        disableSortBy: true,
        Cell: ({ row: { original }, value }) => {
          return <TextOne>{value}</TextOne>;
        },
      },
    ],
    []
  );
  return (
    <>
      <DataTable
        columns={columns}
        data={[
          {
            name: "Access Key",
            key: "ak_test_5AECIU1VUufMck8Fe34bkLNSvQthe2wj9dC0",
            last_used: "-----",
            created: "05/04/2022",
            isRevealed: true,
          },
          {
            name: "Secret Key",
            key: "ak_test_5AECIU1VUufMck8Fe34bkLNSvQthe2wj9dC0",
            last_used: "-----",
            created: "05/04/2022",
            isRevealed: false,
          },
        ]}
        tableStyles={tableStyles}
        paginationConfig={paginationConfig}
        // handlePaginationChange={props.handlePaginationChange}
      />
      <input id="textcopy" className="link-input" style={{ display: "none" }} />
    </>
  );
};

export default ApiKeysTable;
