import { ACTION_TYPES } from 'src/constants';

export function requestConvertToSaleLead(payload) {
  return {
    type: ACTION_TYPES.REQUEST_CONVERT_TO_SALE_LEAD,
    payload,
  };
}

export function convertToSaleLeadSuccess(payload) {
  return {
    type: ACTION_TYPES.CONVERT_TO_SALE_LEAD_SUCCESS,
    payload,
  };
}

export function convertToSaleLeadFailure(payload) {
  return {
    type: ACTION_TYPES.CONVERT_TO_SALE_LEAD_FAILURE,
    payload,
  };
}