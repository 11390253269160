import { connect } from "react-redux";
import {requestToggleMobileSideMenu, requestBulkUploadLead} from "src/redux/actions";
import Header from "./header";

const mapStateToProps = (state) => {
  return {
    bulkUploadLead: state.bulkUploadLead,
    accessKey: state.adminCredentials.partnerPublicAccessKey,
  };
}

export default connect(mapStateToProps, {requestToggleMobileSideMenu, requestBulkUploadLead})(Header);
