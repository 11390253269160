import { ACTION_TYPES } from 'src/constants';

export function requestShowLead(payload) {
  return {
    type: ACTION_TYPES.REQUEST_SHOW_LEAD,
    payload,
  };
}

export function showLeadSuccess(payload) {
  return {
    type: ACTION_TYPES.SHOW_LEAD_SUCCESS,
    payload,
  };
}

export function showLeadFailure(payload) {
  return {
    type: ACTION_TYPES.SHOW_LEAD_FAILURE,
    payload,
  };
}