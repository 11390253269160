import { ApiRequest } from "src/helpers";
import { API_END_POINTS } from "src/constants";

export function adminWithdrawalList({ page, checkout_type }) {
  return ApiRequest.fetchAdmin({
    method: "get",
    url: `${API_END_POINTS.ADMIN_WITHDRAWAL_LIST}?page=${page}&checkout_type=${checkout_type}`,
  }).then((response) => response.data);
}

// export function createAdminSip(data) {
//   return ApiRequest.fetchAdmin({
//     method: "post",
//     url: API_END_POINTS.ADMIN_CREATE_SIP,
//     data,
//   }).then((response) => response.data);
// }

// export function adminShowSip({ uuid }) {
//   return ApiRequest.fetchAdmin({
//     method: "get",
//     url: `${API_END_POINTS.ADMIN_SHOW_SIP}/${uuid}`,
//   }).then((response) => response.data);
// }
