import { ApiRequest } from "src/helpers";
import { API_END_POINTS } from "src/constants";

export function getNoteKeywords() {
  return ApiRequest.fetchAdmin({
    method: "get",
    url: `${API_END_POINTS.GET_LEAD_NOTE_KEYWORDS}`,
  }).then((response) => response.data.keywords);
}

export function createLeadNotes(data) {
  return ApiRequest.fetchAdmin({
    method: "post",
    url: API_END_POINTS.CREATE_LEAD_NOTES,
    data,
  }).then((response) => response.data);
}