import _ from "lodash";
import { stateToHTML } from "draft-js-export-html";
import { convertFromRaw } from "draft-js";

class Convert {
  static toCamelCase(sourceObject) {
    const result = sourceObject instanceof Array ? [] : {};

    _.each(sourceObject, (value, key) => {
      if (typeof value === "object" && value != null) {
        result[_.camelCase(key)] = this.toCamelCase(value);
      } else result[_.camelCase(key)] = value;
    });

    return result;
  }

  /**
   * Returns the base64 data for array of image file objects
   *
   */
  static async toDataUri_(files) {
    const getDataUri = (file) =>
      new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          resolve(event.target.result);
        };
        reader.readAsDataURL(file);
      });

    const dataUriData = Object.values(files).map(async (file) => {
      try {
        const dataUri = await getDataUri(file);
        return dataUri;
      } catch (error) {
        console.log(error);
      }
    });

    return dataUriData;
  }

  // static async toDataUri2(files){

  //   const getDataUri = file => new Promise((resolve) => {
  //     const reader = new FileReader();
  //     reader.onload = (event) => {
  //       resolve(event.target.result)
  //     };
  //     reader.readAsDataURL(file);
  //   })

  // }

  static toDataUri(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.readAsDataURL(file);
    });
  }

  /**
   * Returns a string in indian number format with optional currency symbol
   * @param {Number} sourceValue
   * @returns currency value
   * example: 20000 -> 20,000
   */
  static toCurrencyValue(
    sourceVal,
    options = { currencyDisplay: "symbol", allowCurrencyOnly: true }
  ) {
    const { currencyDisplay, allowCurrencyOnly = true } = options;

    if (!sourceVal && allowCurrencyOnly) {
      return parseInt(0).toLocaleString("en-IN", {
        style: "currency",
        currency: "INR",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    }

    let sourceValue = sourceVal && sourceVal.toString().replace(/,/g, "");

    sourceValue = parseInt(sourceValue) || "";

    let currencyOptions = {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    };

    if (currencyDisplay !== "none") {
      currencyOptions = {
        ...currencyOptions,
        style: "currency",
        currency: "INR",
        currencyDisplay,
      };
    }

    return sourceValue.toLocaleString("en-IN", {
      ...currencyOptions,
    });
  }

  static toCurrencyFormat(sourceVal) {
    // let sourceValue = sourceVal && sourceVal.toString().replace(/,/g, '');

    // sourceValue = Number(sourceValue) || ''

    return Number(sourceVal).toLocaleString("en-IN", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 0,
      style: "currency",
      currency: "INR",
      currencyDisplay: "symbol",
    });
  }

  static toAbbreviatedNumber(sourceVal) {
    let val = Number(sourceVal).toFixed(1) || "";

    if (val >= 100000) {
      // lakh
      val = Number(val / 100000);
      return val % 1 > 0 ? `₹${val.toFixed(1)}L` : `₹${val}L`;
    }

    if (val >= 1000) {
      // thousand
      val = Number(val / 1000);
      return val % 1 > 0 ? `₹${val.toFixed(1)}k` : `₹${val}k`;
    }

    return `₹${val}`;
  }

  /**
   * Returns a percentage for given amount example: val: 21, total: 100
   * @param {*} value
   * @param {*} total
   * @param {int} to decimal places
   */
  static toPercentage(v, t, d = 2) {
    let val = Number(v);
    let total = Number(t);
    const percentage = ((val / total) * 100).toFixed(d);

    return Number(percentage);
  }

  static toTitleCase(sourceText) {
    return sourceText
      ? sourceText
          .split(" ")
          .map(
            (w) =>
              (w[0] || "").toUpperCase() + (w || "").substr(1).toLowerCase()
          )
          .join(" ")
      : "";
  }

  static currency(amount) {
    let value = parseFloat(amount);

    if (value % 1 !== 0) {
      value = value.toFixed(2);
    }

    return `₹${value
      .toString()
      .replace(/(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g, "$1,")}`;
  }

  static toRawHtml(editorData) {
    let data = {};
    new Array(100).fill(0).map((d, index) => {
      data = {
        ...data,
        [`fontsize-${index + 1}`]: {
          style: { fontSize: index + 1 },
        },
      };
      return data;
    });
    let options = {
      blockStyleFn: (block) => {
        let data = block.getData();
        if (data.size == 0) return;

        let style = {};
        if (data.get("text-align"))
          style = { ...style, textAlign: data.get("text-align") };
        return { style };
      },
      inlineStyles: {
        ...data,
      },
    };
    return (
      (editorData &&
        stateToHTML(convertFromRaw(JSON.parse(editorData)), options)) ||
      ""
    );
  }

  static roundDownAmount(amount, decimals = 2) {
    const parsedAmount = Number(amount);
    return Math.floor((parsedAmount * 10 ** decimals) / 10 ** decimals);
  }

  static toCapitalizeLetters = (sentence = "") => {
    let newSentence = [];
    let splittedSentence = sentence.toLowerCase();
    splittedSentence = splittedSentence.split(" ") || [];
    splittedSentence.forEach((word) => {
      const updatedWord = word.charAt(0).toUpperCase() + word.slice(1);
      newSentence.push(updatedWord);
    });
    return newSentence.toString().replaceAll(",", " ");
  };
}

export { Convert };
