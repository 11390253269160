import { ApiRequest } from "src/helpers";
import { API_END_POINTS } from "src/constants";

export function adminGenericLinksList({ page }) {
  return ApiRequest.fetchAdmin({
    method: "get",
    url: `${API_END_POINTS.ADMIN_GENERIC_LINKS_LIST}?page=${page}`,
  }).then((response) => response.data);
}

export function createAdminGenericLink(data) {
  return ApiRequest.fetchAdmin({
    method: "post",
    url: API_END_POINTS.ADMIN_CREATE_GENERIC_LINK,
    data,
  }).then((response) => response.data);
}

export function updateAdminGenericLink(data) {
  return ApiRequest.fetchAdmin({
    method: "put",
    url: `${API_END_POINTS.ADMIN_SHOW_GENERIC_LINK}/${data.id}`,
    data,
  }).then((response) => response.data);
}

export function adminShowGenericLink({ uuid }) {
  return ApiRequest.fetchAdmin({
    method: "get",
    url: `${API_END_POINTS.ADMIN_SHOW_GENERIC_LINK}/${uuid}`,
  }).then((response) => response.data);
}
