import React from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { Loader } from "src/components";

function AdminProtectedRoute({ component: Component, ...restOfProps }) {
  let { token, adminLoginSuccess, requestAdminVerifyToken } = restOfProps;
  const isAuthenticated = token;
  const redirectRoute = "/admin/sign-in";
  const history = useHistory()
  const queryParams = new URLSearchParams(window.location.search)

  if(!isAuthenticated) {
    token = queryParams.get("token")
  }

  if(token && !isAuthenticated) {
    requestAdminVerifyToken({token: token})
    return (<Loader loading={true} />)
  } else {
    if(queryParams.has("token")) {
      queryParams.delete("token")
      history.replace({search: queryParams.toString()})
    }

    return (
      <Route
        {...restOfProps}
        render={(props) =>
          isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect to={redirectRoute} />
          )
        }
      />
    );
  }
}

export default AdminProtectedRoute;
