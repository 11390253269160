import { ACTION_TYPES } from "src/constants";
import { INITIAL_STATE } from "../store";

export default function CredentialReducer(
  state = INITIAL_STATE.adminCredentials,
  action
) {
  switch (action.type) {
    case ACTION_TYPES.ADMIN_LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        partner: action.payload.partner,
        adminUser: action.payload.adminUser,
        partnerPublicAccessKey: action.payload.partnerPublicAccessKey,
      };

    case ACTION_TYPES.ADMIN_VERIFY_OTP_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        partner: action.payload.partner,
        adminUser: action.payload.adminUser,
        partnerPublicAccessKey: action.payload.partnerPublicAccessKey,
      };

    case ACTION_TYPES.ADMIN_VERIFY_TOKEN_SUCCESS: {
      return {
        ...state,
        token: action.payload.token,
        partner: action.payload.partner,
        adminUser: action.payload.adminUser
      }
    }

    case ACTION_TYPES.SET_SESSION:
      return {
        ...state,
        ...action.payload
      }

    case ACTION_TYPES.ADMIN_LOGOUT_SUCCESS:
      return INITIAL_STATE.adminCredentials;

    default:
      return state;
  }
}
