import { takeEvery, call, put } from "redux-saga/effects";
import { ACTION_TYPES } from "src/constants";
import { leadRecommendationsApi } from "src/redux/api";
import {
    leadRecommendationsSuccess,
    leadRecommendationsFailure,
} from "src/redux/actions";

export function* watchLeadRecommendations() {
  yield takeEvery(ACTION_TYPES.REQUEST_LEAD_RECOMMENDATIONS, requestLeadRecommendations);
}

function* requestLeadRecommendations(action) {
  try {
    const leadRecommendationsData = yield call(leadRecommendationsApi, action.payload);
    yield put(leadRecommendationsSuccess(leadRecommendationsData));
  } catch (e) {
    yield put(leadRecommendationsFailure(e));
  }
}