import { takeEvery, call, put } from "redux-saga/effects";
import { ACTION_TYPES } from "src/constants";
import { createLead } from "src/redux/api";
import {
    createLeadSuccess,
    createLeadFailure,
} from "src/redux/actions";

export function* watchCreateLeadsRequest() {
  yield takeEvery(ACTION_TYPES.REQUEST_CREATE_LEADS, requestCreateLead);
}

function* requestCreateLead(action) {
  try {
    const createLeadData = yield call(createLead, action.payload);
    yield put(createLeadSuccess(createLeadData));
  } catch (e) {
    yield put(createLeadFailure(e));
  }
}