import { ACTION_TYPES } from "src/constants";

export function requestAdminFunds(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_FUNDS,
    payload,
  };
}

export function requestAdminFundsSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_FUNDS_SUCCESS,
    payload,
  };
}

export function requestAdminFundsFailed(payload) {
  return {
    type: ACTION_TYPES.ADMIN_FUNDS_FAILED,
    payload,
  };
}
