import React, { Component } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import EventBus from "eventing-bus";
import { Label } from "src/components/theme";
import { ACTION_TYPES } from "src/constants";
import { paymentMethods } from "src/constants";
import { TextInputOne } from "src/components/form-inputs";
import styles from "./_read-modal.module.scss";
import { isArray } from "lodash";
import Grid from "@mui/material/Grid";
import Select from "react-select";
import _ from "lodash";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

export default class ReadModal extends Component {
  state = {
    isLoading: false,
    selectedLandingPage: null,
    isUpdateButtonActive: false,
  };

  componentDidMount() {
    const { data } = this.props;

    this.props.requestAdminShowGenericLink({ uuid: data?.uuid });

    this.adminShowGenericLinkSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_ADMIN_SHOW_GENERIC_LINK,
      () => {
        this.setState({ isLoading: true });
      }
    );

    this.adminShowGenericLinkSubscriptionSuccess = EventBus.on(
      ACTION_TYPES.ADMIN_SHOW_GENERIC_LINK_SUCCESS,
      (data) => {
        const { checkout } = data || {};
        let amount = checkout?.funds.reduce(
          (prev, current) => prev + +current.amount,
          0
        );
        this.setState({ isLoading: false });
        this.formik.setFieldValue("id", checkout?.uuid);
        this.formik.setFieldValue("name", checkout?.nickname);
        this.formik.setFieldValue("amount", amount);
      }
    );

    this.adminShowGenericLinkSubscriptionFailure = EventBus.on(
      ACTION_TYPES.ADMIN_SHOW_GENERIC_LINK_FAILED,
      () => this.setState({ isLoading: false })
    );

    this.requestUpdateGenericLink = EventBus.on(
      ACTION_TYPES.REQUEST_ADMIN_UPDATE_GENERIC_LINK,
      () => this.setState({ isLoading: true })
    );
    this.requestUpdateGenericLinkSuccess = EventBus.on(
      ACTION_TYPES.ADMIN_UPDATE_GENERIC_LINK_SUCCESS,
      this.onUpdateSuccess
    );
    this.requestUpdateGenericLinkFailure = EventBus.on(
      ACTION_TYPES.ADMIN_UPDATE_GENERIC_LINK_FAILED,
      () => this.setState({ isLoading: false })
    );

    this.requestLandingPages = EventBus.on(
      ACTION_TYPES.REQUEST_LANDING_PAGES,
      () => this.setState({ isLoading: true })
    );
    this.requestLandingPagesSuccess = EventBus.on(
      ACTION_TYPES.REQUEST_LANDING_PAGES_SUCCESS,
      () => this.setState({ isLoading: false })
    );
    this.requestLandingPagesFailure = EventBus.on(
      ACTION_TYPES.REQUEST_LANDING_PAGES_FAILURE,
      () => this.setState({ isLoading: false })
    );

    this.props.requestLandingPages({});
  }

  componentWillUnmount() {
    this.adminShowGenericLinkSubscription();
    this.adminShowGenericLinkSubscriptionSuccess();
    this.adminShowGenericLinkSubscriptionFailure();

    this.requestUpdateGenericLink();
    this.requestUpdateGenericLinkSuccess();
    this.requestUpdateGenericLinkFailure();

    this.requestLandingPages();
    this.requestLandingPagesSuccess();
    this.requestLandingPagesFailure();
  }

  onCopyLink = ({ link = "" }) => {
    navigator.clipboard.writeText(link);
    toast.success("Link copied to clipboard", "success");
  };

  handleLandingPageChange = ({ value }) => {
    this.setState({ isUpdateButtonActive: true, selectedLandingPage: value });
  };

  onUpdate = () => {
    this.props.requestAdminUpdateGenericLink({
      id: this.props.genericLink.checkout?.uuid,
      checkout: {
        landing_page_uuid: this.state.selectedLandingPage?.value,
      },
    });
  };

  onUpdateSuccess = () => {
    toast.success("Updated successfully!", "success");
  };

  render() {
    const { show, handleClose, genericLink, landingPages } = this.props;
    let paymentModes = [];

    if (isArray(genericLink?.checkout?.paymentModes)) {
      paymentModes = genericLink?.checkout?.paymentModes;
    }

    return (
      <div>
        <input id="input-link" className={styles["link-input"]} />
        <Dialog
          open={Boolean(show)}
          fullWidth={true}
          maxWidth="md"
          onClose={handleClose}
        >
          <div className={styles["title"]}>
            {this.state.isLoading ? "Fetching data..." : "Generic Link Detail"}
          </div>
          <Formik
            initialValues={{
              id: "",
              amount: "",
              fund: "",
              status: "",
            }}
            onSubmit={handleClose}
            innerRef={(ref) => (this.formik = ref)}
          >
            <Form>
              <div className={styles["content"]}>
                {genericLink?.checkout?.shortLink && (
                  <div>
                    <br />
                    <div className={styles["link"]}>
                      <a href="#">{genericLink?.checkout?.shortLink}</a>
                    </div>
                    <div
                      className={styles["copy-link"]}
                      onClick={() =>
                        this.onCopyLink({
                          link: genericLink?.checkout?.shortLink,
                        })
                      }
                    >
                      <img src="/img/copylink.svg" alt="copylink" />← Please
                      click to copy this transaction
                    </div>
                    <br />
                    <br />
                  </div>
                )}
                <TextInputOne
                  name="id"
                  id="id"
                  label="ID"
                  type="text"
                  placeholder="ID"
                  sizeType="medium"
                  disabled
                />
                <TextInputOne
                  name="name"
                  id="name"
                  label="Name of link"
                  type="text"
                  placeholder="Name of link"
                  sizeType="medium"
                  disabled
                />

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <div
                      className={styles["label"]}
                      style={{ marginBottom: 5 }}
                    >
                      Choose landing page (Optional)
                    </div>

                    <div className={styles["fund-amount-selectbox"]}>
                      <Select
                        defaultValue={{
                          label: "Choose a landing page",
                          value: "",
                        }}
                        className={styles["fundlable"]}
                        onChange={(e) =>
                          this.handleLandingPageChange({
                            value: e,
                          })
                        }
                        options={
                          _.map(landingPages, (page) => ({
                            label: page.landingPageName + " - " + page.uuid,
                            value: page.uuid,
                          })) || []
                        }
                      ></Select>
                    </div>
                  </Grid>
                </Grid>

                {genericLink?.checkout?.funds &&
                  genericLink?.checkout?.funds.length > 0 && (
                    <Label sizeType="medium">Fund(s)</Label>
                  )}
                <div style={{ marginTop: "4px", marginBottom: "12px" }}>
                  {genericLink?.checkout?.funds &&
                    genericLink?.checkout?.funds.map((data, index) => (
                      <div style={{ marginLeft: "4px" }}>
                        <Label sizeType="small">
                          {index + 1}. {data?.fund?.name || data?.name}
                        </Label>
                      </div>
                    ))}
                </div>

                {paymentModes.length > 0 && (
                  <Label sizeType="medium">Payment Method(s)</Label>
                )}
                <div style={{ marginTop: "4px", marginBottom: "12px" }}>
                  {paymentModes.map((data, index) => (
                    <div style={{ marginLeft: "4px" }}>
                      <Label sizeType="small">
                        {index + 1}.{" "}
                        {paymentMethods.find((d) => d.value === data)?.name}
                      </Label>
                    </div>
                  ))}
                </div>

                <div className={styles["footer"]}>
                  <Stack direction="row" spacing={2}>
                    <Button
                      disabled={!this.state.isUpdateButtonActive}
                      onClick={this.onUpdate}
                      variant="contained"
                    >
                      Update
                    </Button>
                    <Button onClick={handleClose} variant="outlined">
                      Cancel
                    </Button>
                  </Stack>
                </div>
              </div>
            </Form>
          </Formik>
        </Dialog>
      </div>
    );
  }
}
