import { connect } from 'react-redux';
import LeadsTable from './leads-table';
import {
 requestConvertToSaleLead,
} from "src/redux/actions";

const mapStateToProps = (state) =>  {
  return {
    
  }
}
export default connect(mapStateToProps, {requestConvertToSaleLead})(LeadsTable);