import { ACTION_TYPES } from 'src/constants';

export function requestBulkUploadLead(payload) {
  return {
    type: ACTION_TYPES.REQUEST_BULK_UPLOAD_LEAD,
    payload,
  };
}

export function bulkUploadLeadSuccess(payload) {
  return {
    type: ACTION_TYPES.BULK_UPLOAD_LEAD_SUCCESS,
    payload,
  };
}

export function bulkUploadLeadFailure(payload) {
  return {
    type: ACTION_TYPES.BULK_UPLOAD_LEAD_FAILURE,
    payload,
  };
}