import { ACTION_TYPES } from "src/constants";
import { INITIAL_STATE } from "../store";

export default function RiskProfilesReducer(
  state = INITIAL_STATE.riskProfiles,
  action
) {
  switch (action.type) {
    case ACTION_TYPES.REQUEST_RISK_PROFILES_SUCCESS:
      return action.payload

    default:
      return state;
  }
}