import { takeEvery, call, put } from "redux-saga/effects";
import { ACTION_TYPES } from "src/constants";
import { showLeadApi } from "src/redux/api";
import {
    showLeadSuccess,
    showLeadFailure,
} from "src/redux/actions";

export function* watchShowLeadApi() {
  yield takeEvery(ACTION_TYPES.REQUEST_SHOW_LEAD, requestShowLead);
}

function* requestShowLead(action) {
  try {
    const showLeadData = yield call(showLeadApi, action.payload);
    yield put(showLeadSuccess(showLeadData));
  } catch (e) {
    yield put(showLeadFailure(e));
  }
}