import React, { Component } from "react";
import EventBus from "eventing-bus";
import { ACTION_TYPES } from "src/constants";
import { Loader, Header } from "src/components";
import { Form, Formik } from "formik";
import moment from "moment";
import {
  TextInputOne,
  ButtonOne,
  InputSwitch,
} from "src/components/form-inputs";
import { withRouter } from "react-router-dom";
import WebhooksTable from "./webhooks-table";
import CreateEditWebhook from "./create-edit-webhook";
import { Container } from "../../../../components";
import styles from "./_webhooks.module.scss";

class Webhooks extends Component {
  state = {
    isLoading: false,
    pageNumber: 1,
    showCreateEditModal: false,
  };

  componentDidMount() {
    this.props.requestAdminWebhookList();
  }

  openCreateEditModal = () => {
    this.setState({ showCreateEditModal: true });
  };

  closeCreateEditModal = () => {
    this.setState({ showCreateEditModal: false });
  };

  formattedData = () => {
    const { webhooks = [] } = this.props;
    return webhooks.map((data) => {
      return { hookName: data };
    });
  };

  render() {
    const { isLoading, showCreateEditModal } = this.state;
    const webhooksData = this.formattedData() || [];

    return (
      <div className={styles["webhooks"]}>
        <Header title="Webhooks" />
        <Container>
          <div className={styles["webhook-box"]}>
            <div className={styles["webhook-title"]}>
              Endpoints receiving events from your account
            </div>
            <ButtonOne onClick={this.openCreateEditModal}>
              <img
                src="/img/plusnew.svg"
                alt="plus"
                className={styles["plus-btn"]}
              />
              <div>Add endpoint</div>
            </ButtonOne>
          </div>
          <div className={styles["table-data"]}>
            <WebhooksTable
              data={webhooksData}
              totalItems={webhooksData.length}
              openCreateEditModal={this.openCreateEditModal}
            />
          </div>
        </Container>
        <CreateEditWebhook
          show={showCreateEditModal}
          handleClose={this.closeCreateEditModal}
        />
        <Loader loading={isLoading} />
      </div>
    );
  }
}

export default withRouter(Webhooks);
