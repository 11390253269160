import axios from "axios";
import _ from "lodash";
import { createBrowserHistory } from "history";
import { setSession, requestLogoutSuccess, adminLogoutSuccess } from "src/redux/actions";
import { toast } from "react-toastify";
import { Convert } from "./convert";
import moment from "moment";
import { API_END_POINTS } from "src/constants";

const ENCRYPTION_REQUIRED_BLACKLIST_APIS = [API_END_POINTS.ADMIN_BATCH_UPLOAD]
const ENCRYPTION_REQUIRED = process.env.REACT_APP_ENCRYPTION_REQUIRED == true || process.env.REACT_APP_ENCRYPTION_REQUIRED == 'true' || false;
const NON_JSON_RESPONSE_APIS = [API_END_POINTS.ADMIN_DOWNLOAD_SAMPLE_BATCH_FILE]

class ApiRequest {
  static async forceLogout() {
    const globalState = global.store.getState()
    let options = {data: {}}
    options.headers = _.merge(this.headers(), options.headers);

    const response = await fetch(API_END_POINTS.ADMIN_LOGOUT_NOENC, {
      method: 'POST',
      keepalive: true,
      headers: options.headers,
      body: JSON.stringify(options.data)
    })

    return response.json()
  }

  static async fetchAdmin(options) {
    const globalState = global.store.getState()

    if(ENCRYPTION_REQUIRED) {
      const sessionKey = globalState.adminCredentials;
      const timeNow = moment()
      if(sessionKey && sessionKey.expiry && timeNow.isAfter(sessionKey.expiry)) {
        // No-op
      } else {
        const key = await this.generateNewRSAKey()
        const exportedKey = await this.exportRSAKey(key)
        const fingerprint = window.crypto.randomUUID()
        const handshakeParams = {
          method: 'post',
          url: API_END_POINTS.HANDSHAKE,
          data: { public_key: window.btoa(exportedKey.publicKey), client_id: fingerprint },
        }
        handshakeParams.headers = Object.assign(this.headers(), handshakeParams.headers);

        const res = await axios(handshakeParams).then((response) => Convert.toCamelCase(response))
        global.store.dispatch(setSession({
          expiry: moment().add(1, "hour").format("YYYY-MM-DD"),
          clientPrKey: exportedKey.privateKey,
          clientPuKey: exportedKey.publicKey,
          serverPuKey: window.atob(res.data.publicKey),
          fingerprint: fingerprint,
        }));
      }
    }

    return this.apiCall(options);
  }

  static async apiCall(options) {
    const globalState = global.store.getState();
    const key = await this.generateNewAESKey();
    const encryptionRequired = ENCRYPTION_REQUIRED && !_.includes(ENCRYPTION_REQUIRED_BLACKLIST_APIS, options.url)

    if (encryptionRequired) {
      const needsBodyEncryption = options.method == "post" || options.method == "put";
      let body = null;

      if(needsBodyEncryption) {
        body = await this.encryptBody(options.data, key)
        options.data = { data: body }
      }
      const encryptedAESKey = await this.publicEncryptAesKey(key)
      const headers = await this.encryptionHeaders(encryptedAESKey, body)
      options.headers = _.merge(headers, options.headers);
    } else {
      options.headers = _.merge(this.headers(), options.headers);
    }

    const needsJSONParsing = !_.includes(NON_JSON_RESPONSE_APIS, options.url)

    return axios(options)
      .then((response) => response.data)
      .catch((errorResponse) => {
        const errors = errorResponse.response && errorResponse.response.data
        const status = errorResponse.response.status
        if(status == 401)
          throw { errors: 'Unauthorized', status: status }
        else if(status >= 500)
          throw { errors: 'Something went wrong, please contact customer support', status: status}

        return errors
      })
      .then((data) => {
        if (encryptionRequired)
          return this.decryptResponseBody(data.data, key);
        else return data;
      })
      .then((decryptedResponse) => {
        let con = decryptedResponse;
        if (encryptionRequired) {
          con = new TextDecoder().decode(decryptedResponse);
          if (needsJSONParsing)
            con = JSON.parse(con)
        }

        return Convert.toCamelCase({ data: con });
      })
      .then((response) => {
        const errors = response?.data?.errors;
        if (!errors) return response;

        let errorMessages = "";
        if (errors) {
          if (errors instanceof Array || errors.full_messages) {
            errorMessages = errors.full_messages
              ? _.join(errors.full_messages, "\n")
              : _.join(errors, "\n");
          } else {
            errorMessages = errors;
          }
        } else {
          errorMessages = "Something went wrong, please try again or contact support";
        }

        this.error(errorMessages, response?.status);

        throw response;
      })
      .catch((response) => {
        if(response?.status)
          this.error(response.errors, response.status)

        throw response
      });
  }

  static async encryptionHeaders(requestId, body) {
    const headers = this.headers()
    const sessionKey = global.store.getState().adminCredentials;

    headers['X-CLIENT-ID'] = sessionKey.fingerprint
    headers['X-REQUEST-ID'] = requestId

    if(body) {
      const rsaKey = await this.importRSAKeyForSigning(sessionKey.clientPrKey)
      const signature = await window.crypto.subtle.sign(
        "RSASSA-PKCS1-v1_5",
        rsaKey,
        (new TextEncoder()).encode(body)
      )
      const exportedSignatureAsString = String.fromCharCode.apply(null, new Uint8Array(signature));
      const exportedSignatureAsBase64 = window.btoa(exportedSignatureAsString);

      headers['X-SIGNATURE'] = exportedSignatureAsBase64
    }

    return headers
  }

  static headers() {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      // "X-PARTNER-ACCESS-KEY": "b8a986b6-bb16-433c-bca8-e52c6ed686f1",
      "X-PARTNER-ACCESS-KEY": "2d9b5d62-5388-4edb-82e9-f8dbc0f506f6",
    };
    const store = global.store.getState();

    if (store.adminCredentials.token)
      headers["Authorization"] = `Bearer ${store.adminCredentials.token}`;
      
    return headers;
  }

  static async error(message, status) {
    toast.error(message, "error");
    if (status === 401) {
      global.store.dispatch(requestLogoutSuccess());
      global.store.dispatch(adminLogoutSuccess());

      setTimeout(() => {
        const history = createBrowserHistory();
        const pathname = process.env.REACT_APP_BASE_LOGIN_PATH || "/admin/sign-in";

        history.push({
          pathname,
          state: {
            redirectTo: history.location?.pathname,
          },
        });
        window.location.reload();
      }, 1000);
    }
    toast.error(message, "error");
  }

  static async encryptBody(data, aesKey) {
    return this.exportFromArrayBuffer(
      await window.crypto.subtle.encrypt(
        {
          name: "AES-CBC",
          iv: (await this.ivFromKey(aesKey)),
        },
        aesKey,
        (new TextEncoder()).encode(JSON.stringify(data)),
      )
    );
  }

  static async decryptResponseBody(body, aesKey) {
    return window.crypto.subtle.decrypt(
      {
        name: "AES-CBC",
        iv: await this.ivFromKey(aesKey),
      },
      aesKey,
      this.str2ab(window.atob(body))
    );
  }

  static async generateNewRSAKey() {
    return await window.crypto.subtle.generateKey(
      {
        name: "RSA-OAEP",
        modulusLength: 2048,
        publicExponent: new Uint8Array([1, 0, 1]),
        hash: "SHA-256",
      },
      true,
      ["encrypt", "decrypt"]
    )
  }

  static async exportRSAKey(key) {
    const exported = await window.crypto.subtle.exportKey("spki", key.publicKey)
    const exportedAsString = String.fromCharCode.apply(null, new Uint8Array(exported));
    const exportedAsBase64 = window.btoa(exportedAsString);
    const pemExported = `-----BEGIN PUBLIC KEY-----\n${exportedAsBase64}\n-----END PUBLIC KEY-----`;

    const exportedPrivate = await window.crypto.subtle.exportKey("pkcs8", key.privateKey)
    const exportedPrivateAsString = String.fromCharCode.apply(null, new Uint8Array(exportedPrivate));
    const exportedPrivateAsBase64 = window.btoa(exportedPrivateAsString);
    return {
      publicKey: pemExported,
      privateKey: exportedPrivateAsBase64,
    };
  }

  static async importRSAKeyForSigning(pemEncodedPrivateKey) {
    const decoded64 = window.atob(pemEncodedPrivateKey);
    const binary = this.str2ab(decoded64);
    const imported = await window.crypto.subtle.importKey(
      "pkcs8",
      binary,
      {
        name: "RSASSA-PKCS1-v1_5",
        hash: "SHA-256",
      },
      true,
      ["sign"]
    );

    return imported;
  }

  static async importRSAKeyForEncrypting(pemEncodedPublicKey) {
    const pemHeader = "-----BEGIN PUBLIC KEY-----\n";
    const pemFooter = "\n-----END PUBLIC KEY-----\n";
    const pemContents = pemEncodedPublicKey.substring(
      pemHeader.length,
      pemEncodedPublicKey.length - pemFooter.length
    );

    const decoded64 = window.atob(pemContents);
    const binary = this.str2ab(decoded64);
    const imported = await window.crypto.subtle.importKey(
      "spki",
      binary,
      {
        name: "RSA-OAEP",
        hash: "SHA-256",
      },
      true,
      ["encrypt"]
    );

    return imported;
  }

  static async publicEncryptAesKey(key) {
    const sessionKey = global.store.getState().adminCredentials;
    const rsaKey = await this.importRSAKeyForEncrypting(sessionKey.serverPuKey)
    const exportedKey = await this.exportAESKey(key)

    return this.exportFromArrayBuffer(await window.crypto.subtle.encrypt(
        {
          name: "RSA-OAEP",
        },
        rsaKey,
        (new TextEncoder()).encode(exportedKey),
      )
    );
  }

  static async generateNewAESKey() {
    return await window.crypto.subtle.generateKey(
      {
        name: "AES-CBC",
        length: 256,
      },
      true,
      ["encrypt", "decrypt"]
    )
  }

  static async exportAESKey(key) {
    const exported = await window.crypto.subtle.exportKey("raw", key)
    return this.exportFromArrayBuffer(exported);
  }

  static async ivFromKey(key) {
    const exported = await window.crypto.subtle.exportKey("raw", key)
    return exported.slice(0, 16)
  }

  static exportFromArrayBuffer(buffer) {
    const exportedAsString = String.fromCharCode.apply(null, new Uint8Array(buffer));
    return window.btoa(exportedAsString);
  }

  static str2ab(str) {
    const buf = new ArrayBuffer(str.length);
    const bufView = new Uint8Array(buf);
    for (let i = 0, strLen = str.length; i < strLen; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return buf;
  }
}

export { ApiRequest };