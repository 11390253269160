import { all } from "redux-saga/effects";

import { watchAdminRequests } from "./admin.saga";
import { watchAdminDepositsRequests } from "./admin-deposits.saga";
import { watchAdminSipRequests } from "./admin-sip.saga";
import { watchAdminWithdrawalsRequests } from "./admin-withdrawals.saga";
import { watchAdminGenericLinksRequests } from "./admin-generic-links.saga";
import { watchAdminAmcsRequests } from "./admin-amcs.saga";
import { watchAdminFundsRequests } from "./admin-funds.saga";
import { watchAdminWebhookRequests } from "./admin-webhooks.saga";
import { watchAdminBatchUploadRequests } from "./admin-batch-upload.saga";
import { watchLandingPagesRequests } from './admin-landing-pages.saga';
import { watchBulkUploadLeadApi } from "./bulk-upload-lead.saga";
import { watchLeadApi } from "./lead.saga";
import { watchShowLeadApi } from "./show-lead.saga";
import { watchCreateLeadsRequest } from "./create-leads.saga";
import { watchLeadRecommendations } from "./lead-recommendations.saga";
import { watchCreateLeadRecommendationsRequest } from "./create-lead-recommendations.saga";
import { watchDeleteLeadApi } from "./delete-lead.saga";
import { watchConvertToSaleLead } from "./convert-to-sale-lead.saga";
import { watchBenchmarks } from "./benchmarks.saga";
import { watchUpdateLeadApi } from "./update-lead.saga";
import { watchLeadNotesApi } from "./lead-notes.saga";
import { watchRiskProfiles } from "./risk-profiles.saga";
import { watchLeadRemindersApi } from "./lead-reminders.saga";
import { watchMessageTemplatesApi } from "./message-templates.saga";

export default function* rootSaga() {
  yield all([

    watchAdminRequests(),
    watchAdminDepositsRequests(),
    watchAdminSipRequests(),
    watchAdminWithdrawalsRequests(),
    watchAdminGenericLinksRequests(),
    watchAdminAmcsRequests(),
    watchAdminFundsRequests(),
    watchAdminWebhookRequests(),
    watchAdminBatchUploadRequests(),
    watchLandingPagesRequests(),
    watchBulkUploadLeadApi(),
    watchLeadApi(),
    watchShowLeadApi(),
    watchDeleteLeadApi(),
    watchCreateLeadsRequest(),
    watchLeadRecommendations(),
    watchCreateLeadRecommendationsRequest(),
    watchConvertToSaleLead(),
    watchBenchmarks(),
    watchUpdateLeadApi(),
    watchLeadNotesApi(),
    watchRiskProfiles(),
    watchLeadRemindersApi(),
    watchMessageTemplatesApi(),
  ]);
}
