import { ACTION_TYPES } from "src/constants";
import { INITIAL_STATE } from "../store";

export default function AdminSipReducer(
  state = INITIAL_STATE.adminSip,
  action
) {
  switch (action.type) {
    case ACTION_TYPES.ADMIN_SIP_SUCCESS:
      return {
        ...state,
        sipList: action.payload,
      };

    case ACTION_TYPES.ADMIN_CREATE_SIP_CLEAR:
      return {
        ...state,
        createdSip: {},
      };

    case ACTION_TYPES.ADMIN_CREATE_SIP_SUCCESS:
      return {
        ...state,
        createdSip: action.payload,
      };

    case ACTION_TYPES.ADMIN_SHOW_SIP_SUCCESS:
      return {
        ...state,
        showSip: action.payload,
      };

    case ACTION_TYPES.ADMIN_SIP_FAILED:
      return INITIAL_STATE.adminSip;

    case ACTION_TYPES.REQUEST_ADMIN_LOGIN:
    case ACTION_TYPES.REQUEST_ADMIN_LOGOUT:
    case ACTION_TYPES.ADMIN_LOGOUT_SUCCESS:
      return INITIAL_STATE.adminSip;

    default:
      return state;
  }
}
