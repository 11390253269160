import { connect } from "react-redux";
import { requestAdminWebhookList } from "src/redux/actions";
import Webhooks from "./webhooks";

const mapStateToProps = (state) => ({
  webhooks: state.adminWebhooks?.webhooks,
});

export default connect(mapStateToProps, {
  requestAdminWebhookList,
})(Webhooks);
