import React from "react";
import cx from "classnames";
import styles from "./_textinput.module.scss";
import TextField from "@mui/material/TextField";

export default function TextInput(props) {
  let input = null;

  const onScroll = () => {
    input.blur(); // to disabled increase/decrease inside [type=number]
  };

  const handleChange = event => {
    if(props.inputType == "phoneNumber") {
      event.target.value = event.target.value.replace(/[^0-9+]/gi, '')
    } else if(props.inputType == "panNumber") {
      event.target.value = event.target.value.toUpperCase().replace(/[^0-9A-Z]/gi, '').slice(0,10)
    } else if(props.inputType == "personsName") {
      event.target.value = event.target.value.replace(/[^A-Za-z\s]/gi, '')
    } else if(props.inputType == "email") {
      event.target.value = event.target.value.replace(/[^0-9A-Za-z@.]/gi, '')
    } else if(props.inputType == "amount") {
      event.target.value = event.target.value.replace(/[^0-9.]/gi, '').slice(0,8)
    } else if(props.inputType == "onlysomecharactersallowed") {
      event.target.value = event.target.value.replace(/[^0-9A-Za-z_\-,()\s]/gi, '')
    }
    else if(props.inputType == "distributorCode") {
      event.target.value = event.target.value.replace(/[^0-9A-Za-z\s\-]/gi, '')
    }
    props.onChange && props.onChange(event)
  }

  return (
    <TextField
      ref={(ref) => (input = ref)}
      onWheel={onScroll}
      maxLength={props.inputType == "panNumber" ? 10 : 199}
      className={cx(
        styles.input,
        {
          [styles.inline]: props?.inline,
          [styles[props.sizeType]]: props?.sizeType,
        },
        props.className
      )}
      {...props}
      onChange={handleChange}
    />
  );
}
