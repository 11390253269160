import { connect } from "react-redux";
import AdminProtectedRoute from "./admin-protected-route";
import {
  requestAdminVerifyToken,
  adminLoginSuccess,
} from "src/redux/actions";

function mapStateToProps({ adminCredentials }) {
  const { token } = adminCredentials;

  return { token };
}

export default connect(mapStateToProps, {requestAdminVerifyToken, adminLoginSuccess})(AdminProtectedRoute);
