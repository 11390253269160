import { takeEvery, call, put } from "redux-saga/effects";
import { ACTION_TYPES } from "src/constants";
import { updateLeadApi } from "src/redux/api";
import {
    updateLeadSuccess,
    updateLeadFailure,
} from "src/redux/actions";

export function* watchUpdateLeadApi() {
  yield takeEvery(ACTION_TYPES.REQUEST_UPDATE_LEAD, requestUpdatelead);
}

function* requestUpdatelead(action) {
  try {
    const updateLeadData = yield call(updateLeadApi, action.payload);
    yield put(updateLeadSuccess(updateLeadData));
  } catch (e) {
    yield put(updateLeadFailure(e));
  }
}