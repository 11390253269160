import { ACTION_TYPES } from 'src/constants';

export function requestCreateLead(payload) {
  return {
    type: ACTION_TYPES.REQUEST_CREATE_LEADS,
    payload,
  };
}

export function createLeadSuccess(payload) {
  return {
    type: ACTION_TYPES.CREATE_LEADS_SUCCESS,
    payload,
  };
}

export function createLeadFailure(payload) {
  return {
    type: ACTION_TYPES.CREATE_LEADS_FAILURE,
    payload,
  };
}