import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./_header.module.scss";
import { Button, Typography, IconButton, Box } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
import DescriptionIcon from "@mui/icons-material/Description";

function Header(props) {

  const { isCrm, title } = props;

  const upLoadCsv = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".csv";
    input.onchange = (event) => {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const csv = e.target.result;
        const lines = csv.split("\n");
        const leadsString = lines.join("\n");
        const formattedResponse = JSON.stringify({ leads: { io: leadsString } });
        props.requestBulkUploadLead(formattedResponse)
      };
      reader.readAsText(file);
    };
    input.click();
  };


  return (
    <div className={styles["header"]}>
      <IconButton
        color="inherit"
        edge="start"
        onClick={props.requestToggleMobileSideMenu}
        sx={{ mr: 2, display: { xs: 'block', sm: 'block', md: 'none' } }}
      >
        <MenuIcon />
      </IconButton>
      <div className={styles["header-left"]}>{title}</div>
      <div className={styles["header-right"]}>
        {isCrm ?
          <Button
            onClick={() => {
              upLoadCsv()
            }}
            className={styles["sheet-btn"]}
            startIcon={
              <DescriptionIcon
                style={{ width: "18px", height: "23px", color: "green" }}
              />
            }
            variant="outlined"
          >
            <Typography className="header_btn">Upload CSV</Typography>
          </Button>
        :
          <Box sx={{ display: { xs: 'none', sm: 'block', md: 'block' } }} style={{maxWidth: '300px', marginRight: '12px'}}>
            <Typography variant="subtitle2">Shareable public key </Typography>
            <Typography variant="subtitle2" style={{fontWeight: '600'}}> { props.accessKey } </Typography>
          </Box>
        }
        <Link to="/admin/logout">
          <Button variant="outlined">Logout</Button>
        </Link>
      </div>
    </div>
  );
}

export default Header;
