import { connect } from "react-redux";
import {
  requestAdminAmcs,
  requestAdminFunds,
  requestAdminCreateDeposit,
  requestAdminCheckPan,
  requestAdminFolioList,
  requestAdminFolioListSuccess,
  requestAdminFundsSuccess,
  requestLandingPages,
} from "src/redux/actions";
import CreateEditOneTimeTransaction from "./create-edit-one-time-transaction";

const mapStateToProps = (state) => ({
  adminAmcs: state.adminAmcs,
  adminFunds: state.adminFunds,
  folios: state.adminDeposits?.folioList?.folios || [],
  landingPages: state.landingPages,
  defaultArnNumber: state.adminCredentials?.adminUser?.defaultArnNumber,
  defaultEmployeeCode: state.adminCredentials?.adminUser?.employeeCode,
});

export default connect(mapStateToProps, {
  requestAdminAmcs,
  requestAdminFunds,
  requestAdminCreateDeposit,
  requestAdminCheckPan,
  requestAdminFolioList,
  requestAdminFolioListSuccess,
  requestAdminFundsSuccess,
  requestLandingPages
})(CreateEditOneTimeTransaction);
