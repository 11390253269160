import { ACTION_TYPES } from 'src/constants';

export function requestLogout(payload) {
  return {
    type: ACTION_TYPES.REQUEST_LOGOUT,
    payload,
  };
}

export function requestLogoutSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_LOGOUT_SUCCEEDED,
    payload,
  };
}

export function requestLogoutFailure(payload) {
  return {
    type: ACTION_TYPES.REQUEST_LOGOUT_FAILED,
    payload,
  };
}