import { takeEvery, call, put } from "redux-saga/effects";
import { ACTION_TYPES } from "src/constants";
import { createMessageTemplate, updateMessageTemplate,
  getMessageTemplates, generateMessageFromTemplate } from "src/redux/api";
import {
  requestCreateMessageTemplateSuccess,
  requestUpdateMessageTemplateSuccess,
  requestMessageTemplatesSuccess,
  requestGenerateMessageFromTemplateSuccess,
  requestCreateMessageTemplateFailed,
  requestUpdateMessageTemplateFailed,
  requestMessageTemplatesFailed,
  requestGenerateMessageFromTemplateFailed,
} from "src/redux/actions";

export function* watchMessageTemplatesApi() {
  yield takeEvery(ACTION_TYPES.REQUEST_CREATE_MESSAGE_TEMPLATE, requestCreateMessageTemplate);
  yield takeEvery(ACTION_TYPES.REQUEST_UPDATE_MESSAGE_TEMPLATE, requestUpdateMessageTemplate);
  yield takeEvery(ACTION_TYPES.REQUEST_MESSAGE_TEMPLATES, requestMessageTemplates);
  yield takeEvery(ACTION_TYPES.REQUEST_GENERATE_MESSAGE_FROM_TEMPLATE, requestGenerateMessage);
}

function* requestCreateMessageTemplate(action) {
  try {
    const data = yield call(createMessageTemplate, action.payload);
    yield put(requestCreateMessageTemplateSuccess(data));
  } catch (e) {
    yield put(requestCreateMessageTemplateFailed(e));
  }
}

function* requestUpdateMessageTemplate(action) {
  try {
    const data = yield call(updateMessageTemplate, action.payload);
    yield put(requestUpdateMessageTemplateSuccess(data));
  } catch (e) {
    yield put(requestUpdateMessageTemplateFailed(e));
  }
}

function* requestMessageTemplates(action) {
  try {
    const data = yield call(getMessageTemplates, action.payload);
    yield put(requestMessageTemplatesSuccess(data));
  } catch (e) {
    yield put(requestMessageTemplatesFailed(e));
  }
}

function* requestGenerateMessage(action) {
  try {
    const data = yield call(generateMessageFromTemplate, action.payload);
    yield put(requestGenerateMessageFromTemplateSuccess(data));
  } catch (e) {
    yield put(requestGenerateMessageFromTemplateFailed(e));
  }
}