import { ACTION_TYPES } from 'src/constants';

export function requestCreateLeadReminder(payload) {
  return {
    type: ACTION_TYPES.REQUEST_CREATE_LEAD_REMINDER,
    payload,
  };
}

export function requestCreateLeadReminderSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_CREATE_LEAD_REMINDER_SUCCESS,
    payload,
  };
}

export function requestCreateLeadReminderFailed(payload) {
  return {
    type: ACTION_TYPES.REQUEST_CREATE_LEAD_REMINDER_FAILED,
    payload,
  };
}

export function requestUpdateLeadReminder(payload) {
  return {
    type: ACTION_TYPES.REQUEST_UPDATE_LEAD_REMINDER,
    payload,
  };
}

export function requestUpdateLeadReminderSuccess(payload) {
  return {
    type: ACTION_TYPES.REQUEST_UPDATE_LEAD_REMINDER_SUCCESS,
    payload,
  };
}

export function requestUpdateLeadReminderFailed(payload) {
  return {
    type: ACTION_TYPES.REQUEST_UPDATE_LEAD_REMINDER_FAILED,
    payload,
  };
}