export const ACTION_TYPES = {
    REDUX_PERSIST                         : 'persist/PERSIST',
    REDUX_PERSIST_FLUSH                   : 'persist/FLUSH',
    REDUX_PERSIST_REHYDRATE               : 'persist/REHYDRATE',
    REDUX_PERSIST_PAUSE                   : 'persist/PAUSE',
    REDUX_PERSIST_PURGE                   : 'persist/PURGE',
    REDUX_PERSIST_REGISTER                : 'persist/REGISTER',

    SET_SESSION                     : 'SET_SESSION',

    REQUEST_LOGOUT                  : 'REQUEST_LOGOUT',
    REQUEST_LOGOUT_SUCCEEDED        : 'REQUEST_LOGOUT_SUCCEEDED',
    REQUEST_LOGOUT_FAILED           : 'REQUEST_LOGOUT_FAILED',

    REQUEST_ADMIN_LOGIN     : 'REQUEST_ADMIN_LOGIN',
    ADMIN_LOGIN_SUCCESS     : 'ADMIN_LOGIN_SUCCESS',
    ADMIN_LOGIN_FAILED      : 'ADMIN_LOGIN_FAILED',

    REQUEST_ADMIN_VERIFY_OTP : 'REQUEST_ADMIN_VERIFY_OTP',
    ADMIN_VERIFY_OTP_SUCCESS : 'ADMIN_VERIFY_OTP_SUCCESS',
    ADMIN_VERIFY_OTP_FAILED : 'ADMIN_VERIFY_OTP_FAILED',

    REQUEST_ADMIN_LOGOUT     : 'REQUEST_ADMIN_LOGOUT',
    ADMIN_LOGOUT_SUCCESS     : 'ADMIN_LOGOUT_SUCCESS',
    ADMIN_LOGOUT_FAILED      : 'ADMIN_LOGOUT_FAILED',

    REQUEST_ADMIN_VERIFY_TOKEN : 'REQUEST_ADMIN_VERIFY_TOKEN',
    ADMIN_VERIFY_TOKEN_SUCCESS : 'ADMIN_VERIFY_TOKEN_SUCCESS',
    ADMIN_VERIFY_TOKEN_FAILED  : 'ADMIN_VERIFY_TOKEN_FAILED',

    REQUEST_ADMIN_AD_LOGIN : 'REQUEST_ADMIN_AD_LOGIN',
    ADMIN_AD_LOGIN_SUCCESS : 'ADMIN_AD_LOGIN_SUCCESS',
    ADMIN_AD_LOGIN_FAILED  : 'ADMIN_AD_LOGIN_FAILED',

    REQUEST_ADMIN_DEPOSITS  : 'REQUEST_ADMIN_DEPOSITS',
    ADMIN_DEPOSITS_SUCCESS  : 'ADMIN_DEPOSITS_SUCCESS',
    ADMIN_DEPOSITS_FAILED   : 'ADMIN_DEPOSITS_FAILED',

    REQUEST_ADMIN_CREATE_DEPOSITS  : 'REQUEST_ADMIN_CREATE_DEPOSITS',
    ADMIN_CREATE_DEPOSITS_SUCCESS  : 'ADMIN_CREATE_DEPOSITS_SUCCESS',
    ADMIN_CREATE_DEPOSITS_FAILED   : 'ADMIN_CREATE_DEPOSITS_FAILED',
    ADMIN_CREATE_DEPOSITS_CLEAR    : 'ADMIN_CREATE_DEPOSITS_CLEAR',

    REQUEST_ADMIN_SIP  : 'REQUEST_ADMIN_SIP',
    ADMIN_SIP_SUCCESS  : 'ADMIN_SIP_SUCCESS',
    ADMIN_SIP_FAILED   : 'ADMIN_SIP_FAILED',

    REQUEST_ADMIN_WITHDRAWALS : 'REQUEST_ADMIN_WITHDRAWALS',
    ADMIN_WITHDRAWALS_SUCCESS : 'ADMIN_WITHDRAWALS_SUCCESS',
    ADMIN_WITHDRAWALS_FAILED : 'ADMIN_WITHDRAWALS_FAILED',

    REQUEST_ADMIN_CREATE_SIP  : 'REQUEST_ADMIN_CREATE_SIP',
    ADMIN_CREATE_SIP_SUCCESS  : 'ADMIN_CREATE_SIP_SUCCESS',
    ADMIN_CREATE_SIP_FAILED   : 'ADMIN_CREATE_SIP_FAILED',
    ADMIN_CREATE_SIP_CLEAR    : 'ADMIN_CREATE_SIP_CLEAR',

    REQUEST_ADMIN_GENERIC_LINKS       : 'REQUEST_ADMIN_GENERIC_LINKS',
    ADMIN_GENERIC_LINKS_SUCCESS       : 'ADMIN_GENERIC_LINKS_SUCCESS',
    ADMIN_GENERIC_LINKS_FAILED        : 'ADMIN_GENERIC_LINKS_FAILED',
    REQUEST_ADMIN_CREATE_GENERIC_LINK : 'REQUEST_ADMIN_CREATE_GENERIC_LINK',
    ADMIN_CREATE_GENERIC_LINK_SUCCESS : 'ADMIN_CREATE_GENERIC_LINK_SUCCESS',
    ADMIN_CREATE_GENERIC_LINK_FAILED  : 'ADMIN_CREATE_GENERIC_LINK_FAILED',
    REQUEST_ADMIN_UPDATE_GENERIC_LINK : 'REQUEST_ADMIN_UPDATE_GENERIC_LINK',
    ADMIN_UPDATE_GENERIC_LINK_SUCCESS : 'ADMIN_UPDATE_GENERIC_LINK_SUCCESS',
    ADMIN_UPDATE_GENERIC_LINK_FAILED  : 'ADMIN_UPDATE_GENERIC_LINK_FAILED',
    ADMIN_CREATE_GENERIC_LINK_CLEAR   : 'ADMIN_CREATE_GENERIC_LINK_CLEAR',
    REQUEST_ADMIN_SHOW_GENERIC_LINK   : 'REQUEST_ADMIN_SHOW_GENERIC_LINK',
    ADMIN_SHOW_GENERIC_LINK_SUCCESS   : 'ADMIN_SHOW_GENERIC_LINK_SUCCESS',
    ADMIN_SHOW_GENERIC_LINK_FAILED    : 'ADMIN_SHOW_GENERIC_LINK_FAILED',

    REQUEST_ADMIN_AMCS  : 'REQUEST_ADMIN_AMCS',
    ADMIN_AMCS_SUCCESS  : 'ADMIN_AMCS_SUCCESS',
    ADMIN_AMCS_FAILED   : 'ADMIN_AMCS_FAILED',

    REQUEST_ADMIN_FUNDS  : 'REQUEST_ADMIN_FUNDS',
    ADMIN_FUNDS_SUCCESS  : 'ADMIN_FUNDS_SUCCESS',
    ADMIN_FUNDS_FAILED   : 'ADMIN_FUNDS_FAILED',
    
    REQUEST_ADMIN_WEBHOOKS  : 'REQUEST_ADMIN_WEBHOOKS',
    ADMIN_WEBHOOKS_SUCCESS  : 'ADMIN_WEBHOOKS_SUCCESS',
    ADMIN_WEBHOOKS_FAILED   : 'ADMIN_WEBHOOKS_FAILED',
    
    REQUEST_ADMIN_CURRENT_WEBHOOKS  : 'REQUEST_ADMIN_CURRENT_WEBHOOKS',
    ADMIN_CURRENT_WEBHOOKS_SUCCESS  : 'ADMIN_CURRENT_WEBHOOKS_SUCCESS',
    ADMIN_CURRENT_WEBHOOKS_FAILED   : 'ADMIN_CURRENT_WEBHOOKS_FAILED',
    
    REQUEST_ADMIN_UPDATE_WEBHOOKS  : 'REQUEST_ADMIN_UPDATE_WEBHOOKS',
    ADMIN_UPDATE_WEBHOOKS_SUCCESS  : 'ADMIN_UPDATE_WEBHOOKS_SUCCESS',
    ADMIN_UPDATE_WEBHOOKS_FAILED   : 'ADMIN_UPDATE_WEBHOOKS_FAILED',

    ADMIN_SANDBOX_TOGGLE   : 'ADMIN_SANDBOX_TOGGLE',
    TOGGLE_MOBILE_SIDE_MENU: 'TOGGLE_MOBILE_SIDE_MENU',

    REQUEST_ADMIN_SHOW_DEPOSIT  : 'REQUEST_ADMIN_SHOW_DEPOSIT',
    ADMIN_SHOW_DEPOSIT_SUCCESS  : 'ADMIN_SHOW_DEPOSIT_SUCCESS',
    ADMIN_SHOW_DEPOSIT_FAILED   : 'ADMIN_SHOW_DEPOSIT_FAILED',

    REQUEST_ADMIN_SHOW_SIP  : 'REQUEST_ADMIN_SHOW_SIP',
    ADMIN_SHOW_SIP_SUCCESS  : 'ADMIN_SHOW_SIP_SUCCESS',
    ADMIN_SHOW_SIP_FAILED   : 'ADMIN_SHOW_SIP_FAILED',
    
    REQUEST_BATCH_UPLOAD_LIST : 'REQUEST_BATCH_UPLOAD_LIST',
    REQUEST_BATCH_UPLOAD_LIST_SUCCESS : 'REQUEST_BATCH_UPLOAD_LIST_SUCCESS',
    REQUEST_BATCH_UPLOAD_LIST_FAILURE : 'REQUEST_BATCH_UPLOAD_LIST_FAILURE',

    REQUEST_BATCH_UPLOAD  : 'REQUEST_BATCH_UPLOAD',
    BATCH_UPLOAD_SUCCESS  : 'BATCH_UPLOAD_SUCCESS',
    BATCH_UPLOAD_FAILED   : 'BATCH_UPLOAD_FAILED',

    REQUEST_SAMPLE_BATCH_FILE : 'REQUEST_SAMPLE_BATCH_FILE',
    REQUEST_SAMPLE_BATCH_FILE_SUCCESS : 'REQUEST_SAMPLE_BATCH_FILE_SUCCESS',
    REQUEST_SAMPLE_BATCH_FILE_FAILURE : 'REQUEST_SAMPLE_BATCH_FILE_FAILURE',

    REQUEST_ADMIN_CHECK_PAN  : 'REQUEST_ADMIN_CHECK_PAN',
    ADMIN_CHECK_PAN_SUCCESS  : 'ADMIN_CHECK_PAN_SUCCESS',
    ADMIN_CHECK_PAN_FAILED   : 'ADMIN_CHECK_PAN_FAILED',

    REQUEST_ADMIN_FOLIO_LIST  : 'REQUEST_ADMIN_FOLIO_LIST',
    ADMIN_FOLIO_LIST_SUCCESS  : 'ADMIN_FOLIO_LIST_SUCCESS',
    ADMIN_FOLIO_LIST_FAILED   : 'ADMIN_CHECK_PAN_FAILED',
    RESET_FOLIO_LIST          : 'RESET_FOLIO_LIST',

    REQUEST_LANDING_PAGES         : 'REQUEST_LANDING_PAGES',
    REQUEST_LANDING_PAGES_SUCCESS : 'REQUEST_LANDING_PAGES_SUCCESS',
    REQUEST_LANDING_PAGES_FAILED  : 'REQUEST_LANDING_PAGES_FAILED',

    REQUEST_CREATE_LANDING_PAGE         : 'REQUEST_CREATE_LANDING_PAGE',
    REQUEST_CREATE_LANDING_PAGE_SUCCESS : 'REQUEST_CREATE_LANDING_PAGE_SUCCESS',
    REQUEST_CREATE_LANDING_PAGE_FAILED  : 'REQUEST_CREATE_LANDING_PAGE_FAILED',

    REQUEST_UPDATE_LANDING_PAGE         : 'REQUEST_UPDATE_LANDING_PAGE',
    REQUEST_UPDATE_LANDING_PAGE_SUCCESS : 'REQUEST_UPDATE_LANDING_PAGE_SUCCESS',
    REQUEST_UPDATE_LANDING_PAGE_FAILED  : 'REQUEST_UPDATE_LANDING_PAGE_FAILED',

    REQUEST_WHATSAPP_TEMPLATES_LIST: 'REQUEST_WHATSAPP_TEMPLATES_LIST',
    REQUEST_WHATSAPP_TEMPLATES_LIST_SUCCESS: 'REQUEST_WHATSAPP_TEMPLATES_LIST_SUCCESS',
    REQUEST_WHATSAPP_TEMPLATES_LIST_FAILURE: 'REQUEST_WHATSAPP_TEMPLATES_LIST_FAILURE',

    REQUEST_BULK_UPLOAD_LEAD: 'REQUEST_BULK_UPLOAD_LEAD',
    BULK_UPLOAD_LEAD_SUCCESS: 'BULK_UPLOAD_LEAD_SUCCESS',
    BULK_UPLOAD_LEAD_FAILURE: 'BULK_UPLOAD_LEAD_FAILURE',

    REQUEST_LEAD: 'REQUEST_LEAD',
    LEAD_SUCCESS: 'LEAD_SUCCESS',
    LEAD_FAILURE: 'LEAD_FAILURE',

    REQUEST_SHOW_LEAD: 'REQUEST_SHOW_LEAD',
    SHOW_LEAD_SUCCESS: 'SHOW_LEAD_SUCCESS',
    SHOW_LEAD_FAILURE: 'SHOW_LEAD_FAILURE',
 
    REQUEST_CREATE_LEADS: 'REQUEST_CREATE_LEADS',
    CREATE_LEADS_SUCCESS: 'CREATE_LEADS_SUCCESS',
    CREATE_LEADS_FAILURE: 'CREATE_LEADS_FAILURE',

    REQUEST_DELETE_LEAD: 'REQUEST_DELETE_LEAD',
    DELETE_LEAD_SUCCESS: 'DELETE_LEAD_SUCCESS',
    DELETE_LEAD_FAILURE: 'DELETE_LEAD_FAILURE',

    REQUEST_LEAD_RECOMMENDATIONS: 'REQUEST_LEAD_RECOMMENDATIONS',
    LEAD_RECOMMENDATIONS_SUCCESS: 'LEAD_RECOMMENDATIONS_SUCCESS',
    LEAD_RECOMMENDATIONS_FAILURE: 'LEAD_RECOMMENDATIONS_FAILURE',

    REQUEST_CREATE_LEAD_RECOMMENDATIONS: 'REQUEST_CREATE_LEAD_RECOMMENDATIONS',
    CREATE_LEAD_RECOMMENDATIONS_SUCCESS: 'CREATE_LEAD_RECOMMENDATIONS_SUCCESS',
    CREATE_LEAD_RECOMMENDATIONS_FAILURE: 'CREATE_LEAD_RECOMMENDATIONS_FAILURE',

    REQUEST_CONVERT_TO_SALE_LEAD: 'REQUEST_CONVERT_TO_SALE_LEAD',
    CONVERT_TO_SALE_LEAD_SUCCESS: 'CONVERT_TO_SALE_LEAD_SUCCESS',
    CONVERT_TO_SALE_LEAD_FAILURE: 'CONVERT_TO_SALE_LEAD_FAILURE',
    
    REQUEST_BENCHMARKS: 'REQUEST_BENCHMARKS',
    REQUEST_BENCHMARKS_SUCCESS: 'REQUEST_BENCHMARKS_SUCCESS',
    REQUEST_BENCHMARKS_FAILURE: ' REQUEST_BENCHMARKS_FAILURE',

    REQUEST_UPDATE_LEAD: 'REQUEST_UPDATE_LEAD',
    UPDATE_LEAD_SUCCESS: 'UPDATE_LEAD_SUCCESS',
    UPDATE_LEAD_FAILURE: 'UPDATE_LEAD_FAILURE',

    REQUEST_NOTE_KEYWORDS:          'REQUEST_NOTE_KEYWORDS',
    REQUEST_NOTE_KEYWORDS_SUCCESS:  'REQUEST_NOTE_KEYWORDS_SUCCESS',
    REQUEST_NOTE_KEYWORDS_FAILED:   'REQUEST_NOTE_KEYWORDS_FAILED',
    REQUEST_CREATE_NOTES:           'REQUEST_CREATE_NOTES',
    REQUEST_CREATE_NOTES_SUCCESS:   'REQUEST_CREATE_NOTES_SUCCESS',
    REQUEST_CREATE_NOTES_FAILED:    'REQUEST_CREATE_NOTES_FAILED',

    REQUEST_RISK_PROFILES: 'REQUEST_RISK_PROFILES',
    REQUEST_RISK_PROFILES_SUCCESS: 'REQUEST_RISK_PROFILES_SUCCESS',
    REQUEST_RISK_PROFILES_FAILED: 'REQUEST_RISK_PROFILES_FAILED',

    REQUEST_CREATE_LEAD_REMINDER: 'REQUEST_CREATE_LEAD_REMINDER',
    REQUEST_CREATE_LEAD_REMINDER_SUCCESS: 'REQUEST_CREATE_LEAD_REMINDER_SUCCESS',
    REQUEST_CREATE_LEAD_REMINDER_FAILED: 'REQUEST_CREATE_LEAD_REMINDER_FAILED',

    REQUEST_UPDATE_LEAD_REMINDER: 'REQUEST_UPDATE_LEAD_REMINDER',
    REQUEST_UPDATE_LEAD_REMINDER_SUCCESS: 'REQUEST_UPDATE_LEAD_REMINDER_SUCCESS',
    REQUEST_UPDATE_LEAD_REMINDER_FAILED: 'REQUEST_UPDATE_LEAD_REMINDER_FAILED',

    REQUEST_CREATE_MESSAGE_TEMPLATE: 'REQUEST_CREATE_MESSAGE_TEMPLATE',
    REQUEST_CREATE_MESSAGE_TEMPLATE_SUCCESS: 'REQUEST_CREATE_MESSAGE_TEMPLATE_SUCCESS',
    REQUEST_CREATE_MESSAGE_TEMPLATE_FAILED: 'REQUEST_CREATE_MESSAGE_TEMPLATE_FAILED',
    
    REQUEST_UPDATE_MESSAGE_TEMPLATE: 'REQUEST_UPDATE_MESSAGE_TEMPLATE',
    REQUEST_UPDATE_MESSAGE_TEMPLATE_SUCCESS: 'REQUEST_UPDATE_MESSAGE_TEMPLATE_SUCCESS',
    REQUEST_UPDATE_MESSAGE_TEMPLATE_FAILED: 'REQUEST_UPDATE_MESSAGE_TEMPLATE_FAILED',
    
    REQUEST_MESSAGE_TEMPLATES: 'REQUEST_MESSAGE_TEMPLATES',
    REQUEST_MESSAGE_TEMPLATES_SUCCESS: 'REQUEST_MESSAGE_TEMPLATES_SUCCESS',
    REQUEST_MESSAGE_TEMPLATES_FAILED: 'REQUEST_MESSAGE_TEMPLATES_FAILED',
    
    REQUEST_GENERATE_MESSAGE_FROM_TEMPLATE: 'REQUEST_GENERATE_MESSAGE_FROM_TEMPLATE',
    REQUEST_GENERATE_MESSAGE_FROM_TEMPLATE_SUCCESS: 'REQUEST_GENERATE_MESSAGE_FROM_TEMPLATE_SUCCESS',
    REQUEST_GENERATE_MESSAGE_FROM_TEMPLATE_FAILED: 'REQUEST_GENERATE_MESSAGE_FROM_TEMPLATE_FAILED',
};