import React, { useState } from "react";
import { GrCheckboxSelected } from "react-icons/gr";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import cx from "classnames";
import styles from "./_create-edit-one-time-transaction.module.scss";

const FundTable = (props) => {
  const { folios, selectedFolio, handleSelectedFolio, isFoliosLoading } = props;

  return (
    <>
      <p className={styles["table-title"]}>Choose a Folio Number</p>
      {isFoliosLoading ? (
        <div className={styles["table-wrap"]}>
          <div className={cx("table-tab")}>Fetching...</div>
        </div>
      ) : (
        <div className={styles["table-wrap"]}>
          <div className={cx("table-tab")}>
            {folios &&
              folios.length > 0 &&
              folios.map((data, index) => (
                <button
                  key={index}
                  className={cx(styles["table-btn"], {
                    active: selectedFolio?.folioNumber === data?.folioNumber,
                  })}
                  onClick={(e) => {
                    e.preventDefault();
                    handleSelectedFolio(data);
                  }}
                >
                  {selectedFolio?.folioNumber === data?.folioNumber && (
                    <GrCheckboxSelected style={{ marginRight: "10px" }} />
                  )}
                  {data?.amcCode + " -- " + data?.folioNumber}
                </button>
              ))}
            {folios.length === 0 ? "No data found" : null}
          </div>
          <TableContainer component={Paper}>
            <Table>
              <TableHead className={styles['tb-head']}>
                {selectedFolio?.portfolio && (
                  <TableRow>
                    <TableCell>Fund name</TableCell>
                    <TableCell>Units held</TableCell>
                    <TableCell>
                      <span>Nav</span>
                      <span>(as per business day)</span>
                    </TableCell>
                  </TableRow>
                )}
              </TableHead>
              <TableBody>
                {selectedFolio?.portfolio &&
                  selectedFolio?.portfolio.length > 0 &&
                  selectedFolio?.portfolio.map((data, index) => (
                    <TableRow key={index}>
                      <TableCell className={styles["tb-defin"]}>{data?.fundName}</TableCell>
                      <TableCell className={styles["tb-defin"]}>{data?.units}</TableCell>
                      <TableCell className={styles["tb-defin"]}>{data?.nav}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </>
  );
};
export default FundTable;
