import { ACTION_TYPES } from "src/constants";
import { INITIAL_STATE } from "../store";

export default function BatchUploadsReducer(
  state = INITIAL_STATE.batchUploads,
  action
) {
  switch (action.type) {
    case ACTION_TYPES.REQUEST_BATCH_UPLOAD_LIST_SUCCESS:
      return action.payload.bulkUploads

    case ACTION_TYPES.REQUEST_ADMIN_LOGIN:
    case ACTION_TYPES.REQUEST_ADMIN_LOGOUT:
    case ACTION_TYPES.ADMIN_LOGOUT_SUCCESS:
      return INITIAL_STATE.batchUploads;

    default:
      return state;
  }
}
