import React, { Component } from "react";
import EventBus from "eventing-bus";
import { ACTION_TYPES } from "src/constants";
import { Loader, Header } from "src/components";
import { Form, Formik } from "formik";
import moment from "moment";
import { TextInputOne, ButtonOne } from "src/components/form-inputs";
import { withRouter } from "react-router-dom";
import SipMandateTable from "./sip-mandate-table";
import { Container } from "../../../../components";
import CreateEditSipMandate from "./create-edit-sip-mandate";
import GeneratedLink from "./generated-link";
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material'
import styles from "./_sip-mandate.module.scss";

class SipMandate extends Component {
  state = {
    isLoading: false,
    pageNumber: 1,
    showCreateEditModal: false,
    showGeneratedLinkModal: false,
    search: "",
  };

  componentDidMount() {
    this.requestSipSubscription = EventBus.on(
      ACTION_TYPES.REQUEST_ADMIN_SIP,
      () => this.setState({ isLoading: true })
    );
    this.sipSubscriptionSuccess = EventBus.on(
      ACTION_TYPES.ADMIN_SIP_SUCCESS,
      this.handleSipuccess
    );
    this.sipSubscriptionFailure = EventBus.on(
      ACTION_TYPES.ADMIN_SIP_FAILED,
      () => this.setState({ isLoading: false })
    );

    this.props.requestAdminSipList({
      page: this.state.pageNumber,
      checkout_type: "ongoing",
    });
  }

  componentWillUnmount() {
    this.requestSipSubscription();
    this.sipSubscriptionSuccess();
    this.sipSubscriptionFailure();
  }

  handleSipuccess = () => {
    this.setState({ isLoading: false });
  };

  _handlePaginationChange = (pageNumber) => {
    this.setState(
      {
        pageNumber,
      },
      () =>
        this.props.requestAdminSipList({
          page: pageNumber,
          checkout_type: "ongoing",
        })
    );
  };

  openCreateEditModal = () => {
    this.setState({ showCreateEditModal: true });
  };

  closeCreateEditModal = () => {
    this.setState({ showCreateEditModal: false });
    this.props.resetFolioList()
  };

  handleOnCompleteCreateSip = () => {
    this.setState({ showCreateEditModal: false, showGeneratedLinkModal: true });
    this.props.resetFolioList()
  };

  handleOnCompleteGeneratedLink = () => {
    this.setState({ showGeneratedLinkModal: false }, () => {
      this.props.requestAdminCreateSipClear();
      this.props.requestAdminSipList({
        page: this.state.pageNumber,
        checkout_type: "ongoing",
      });
    });
  };

  handleSearch = (e) => {
    this.setState({ search: e.target.value.toLowerCase() });
  };

  render() {
    const { isLoading, showCreateEditModal, showGeneratedLinkModal, search } =
      this.state;
    const { sipMandate } = this.props;
    let regex = new RegExp(search, "i");
    const filteredData = (sipMandate?.oneClickCheckouts || []).filter(
      (item) =>
        regex.test(item?.uuid) ||
        regex.test(item?.name.toLowerCase() || "")
    );

    return (
      <div className={styles["one-time-transactions"]}>
        <Header title="SIP / Mandates" />
        <Container>
          <div className={styles["search-box"]}>
            <Formik initialValues={{ search: "" }}>
              <Form>
                <div className={styles["search-input-box"]}>
                  <TextInputOne
                    name="search"
                    id="search"
                    type="text"
                    value={search}
                    onChange={this.handleSearch}
                    placeholder="Search by entering ID or User Name....."
                  />
                  <div className={styles["search-icon"]}>
                    <img src="/img/search.svg" alt="search" />
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
          <div className={styles["table-data"]}>
            <SipMandateTable
              data={filteredData || []}
              handlePaginationChange={this._handlePaginationChange}
              totalItems={sipMandate?.count || 0}
            />
          </div>

          <div className={styles["divider"]} />
          <div className={styles["footer"]}>
            <Button style={{background: 'black'}} variant="contained" startIcon={<AddIcon />} onClick={this.openCreateEditModal}>
              Create New SIP Link
            </Button>
          </div>
        </Container>
        <CreateEditSipMandate
          show={showCreateEditModal}
          handleOnCompleteCreateSip={this.handleOnCompleteCreateSip}
          handleClose={this.closeCreateEditModal}
        />
        <GeneratedLink
          show={showGeneratedLinkModal}
          handleOnCompleteGeneratedLink={this.handleOnCompleteGeneratedLink}
        />
        <Loader loading={isLoading} />
      </div>
    );
  }
}

export default withRouter(SipMandate);
