import { connect } from "react-redux";
import {
  requestAdminDepositList,
  requestAdminCreateDepositClear,
  resetFolioList
} from "src/redux/actions";
import OneTimeTransactions from "./one-time-transactions";

const mapStateToProps = (state) => ({
  onetimeTransactions: state.adminDeposits?.depositsList,
});

export default connect(mapStateToProps, {
  requestAdminDepositList,
  requestAdminCreateDepositClear,
  resetFolioList
})(OneTimeTransactions);
