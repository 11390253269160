import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { requestAdminSandboxToggle } from "src/redux/actions";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Formik, Form } from "formik";
import ListItem from "./list-item";
import { IMAGES } from "src/constants";
import styles from "./_sidenavigation.module.scss";
import { Helpers } from "../../../../helpers";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Container, Typography, TextField, Button } from '@mui/material';

const IS_PRODUCTION = process.env.REACT_APP_ENV === "production";

const SideNavigation = (props) => {
  const [isLogoutSidebar, setIsLogoutSidebar] = useState(false);
  const { sandboxToggle, requestAdminSandboxToggle, partner } = props;

  useEffect(() => {
    if (
      window.location.hostname === "backoffice.thesavvyapp.in" ||
      window.location.hostname === "localhost"
    ) {
      requestAdminSandboxToggle(true);
    } else {
      requestAdminSandboxToggle(false);
    }
  }, []);

  const menuItems = partner.crmOnly ? 
    [{
      id: 1,
      name: "CRM",
      isParentNav: true,
      nav: "/admin/crm/dashboard",
      values: [
        {
          id: 2,
          name: "Leads",
          toggleKey: "/admin",
          nav: "/admin/crm/dashboard"
        },
        {
          id: 3,
          name: "Reminders",
          nav: "/admin/crm/reminders",
        },
      ]
    }] :
    [{
      id: 1,
      name: "Transaction Links",
      isParentNav: true,
      values: [
        {
          id: 2,
          name: "Lumpsum",
          toggleKey: "/admin",
          nav: "/admin"
        },
        {
          id: 3,
          name: "SIPs / Mandates",
          toggleKey: "/admin/analytics",
          nav: "/admin/sip",
        },
        {
          id: 4,
          name: "Bulk Create",
          nav: "/admin/batch-links",
        },
      ]
    },
    {
      id: 5,
      name: "Generic Links",
      nav: "/admin/generic-links"
    },
    {
      id: 6,
      name: "QR Codes (Coming soon!)",
      nav: "/",
    },
    {
      id: 7,
      name: "Landing Pages",
      nav: "/admin/landing-pages"
    }]

  const menuItemsTwo = [
    {
      id: 1,
      name: "Support",
      toggleKey: "/support",
      nav: "mailto: hello@savvyapp.in",
      isExernalLink: true,
    },
  ];

  return (
    <>
      <div className={styles.titleContainer}>
        <div className={styles["savvy-icon"]}>
          <img
            style={{maxWidth:"90%", margin:'5%'}}
            className={styles.logo}
            src={props.partner?.logo}
            alt="PARTNER LOGO"
          />
        </div>
      </div>
      <div className={styles["menu-box"]}>
        <div
          className={cx(styles["menu-box-inner"], {
            [styles["menu-box-inner-inactive"]]: isLogoutSidebar,
          })}
        >
          <ul className={styles.menu}>
            {menuItems &&
              menuItems.length > 0 &&
              menuItems.map((i, index) => (
                <ListItem item={i} key={index} keyIndex={index} />
              ))}
          </ul>
        </div>
      </div>
      <div className={styles.footerContainer}>
        <div className={styles["menu-box"]}>
          <Typography ml={2} mr={2} variant="subtitle2" style={{'color': process.env.REACT_APP_SIDEBAR_TEXT_COLOR || '#fff', opacity: '0.7'}} gutterBottom>
            Last logged in at: {props.lastLoggedInAt}
          </Typography>
          <Typography ml={2} mr={2} variant="subtitle2" style={{'color': process.env.REACT_APP_SIDEBAR_TEXT_COLOR || '#fff', opacity: '0.7'}} gutterBottom>
            Last failed login at: {props.lastFailedLoginAt}
          </Typography>
          <div className={cx(styles["menu-box-inner"])}>
            <ul className={cx(styles.menu, styles.menuTwo)}>
              {menuItemsTwo &&
                menuItemsTwo.length > 0 &&
                menuItemsTwo.map((i, index) => (
                  <ListItem
                    item={i}
                    key={index}
                    keyIndex={index}
                    rightIcon
                    iconImg="/img/ques.svg"
                  />
                ))}
            </ul>
          </div>
          <div className={styles["sandbox"]}>
            <Formik
              initialValues={{
                is_sandbox_mode: sandboxToggle,
              }}
            >
              <Form>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={Helpers.switchMode}
                      checked={sandboxToggle}
                    />
                  }
                  label={`Sandbox Mode ${sandboxToggle ? "On" : "Off"}` }
                  style={{'color': process.env.REACT_APP_SIDEBAR_TEXT_COLOR || '#fff', opacity: '0.7'}}
                />
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  sandboxToggle: state.admin?.sandboxToggle,
  lastLoggedInAt: state.adminCredentials?.adminUser?.lastLoggedInAt,
  lastFailedLoginAt: state.adminCredentials?.adminUser?.lastFailedLoginAt
});

export default connect(mapStateToProps, { requestAdminSandboxToggle })(
  SideNavigation
);