import { ACTION_TYPES } from 'src/constants';

/** admin dashboard deposits */

export function requestAdminDepositList(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_DEPOSITS,
    payload,
  };
}

export function requestAdminDepositListSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_DEPOSITS_SUCCESS,
    payload,
  };
}

export function requestAdminDepositListFailed(payload) {
  return {
    type: ACTION_TYPES.ADMIN_DEPOSITS_FAILED,
    payload,
  };
}

export function requestAdminCreateDeposit(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_CREATE_DEPOSITS,
    payload,
  };
}

export function requestAdminCreateDepositSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_CREATE_DEPOSITS_SUCCESS,
    payload,
  };
}

export function requestAdminCreateDepositFailed(payload) {
  return {
    type: ACTION_TYPES.ADMIN_CREATE_DEPOSITS_FAILED,
    payload,
  };
}

export function requestAdminCreateDepositClear(payload) {
  return {
    type: ACTION_TYPES.ADMIN_CREATE_DEPOSITS_CLEAR,
    payload,
  };
}

export function requestAdminShowDeposit(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_SHOW_DEPOSIT,
    payload,
  };
}

export function requestAdminShowDepositSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_SHOW_DEPOSIT_SUCCESS,
    payload,
  };
}

export function requestAdminShowDepositFailed(payload) {
  return {
    type: ACTION_TYPES.ADMIN_SHOW_DEPOSIT_FAILED,
    payload,
  };
}

export function requestAdminCheckPan(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_CHECK_PAN,
    payload,
  };
}

export function requestAdminCheckPanSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_CHECK_PAN_SUCCESS,
    payload,
  };
}

export function requestAdminCheckPanFailed(payload) {
  return {
    type: ACTION_TYPES.ADMIN_CHECK_PAN_FAILED,
    payload,
  };
}

export function requestAdminFolioList(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_FOLIO_LIST,
    payload,
  };
}

export function requestAdminFolioListSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_FOLIO_LIST_SUCCESS,
    payload,
  };
}

export function requestAdminFolioListFailed(payload) {
  return {
    type: ACTION_TYPES.ADMIN_FOLIO_LIST_FAILED,
    payload,
  };
}

export function resetFolioList(payload) {
  return {
    type: ACTION_TYPES.RESET_FOLIO_LIST,
    payload,
  }
}