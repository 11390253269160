import { ACTION_TYPES } from 'src/constants';

export function requestCreateLeadRecommendations(payload) {
  return {
    type: ACTION_TYPES.REQUEST_CREATE_LEAD_RECOMMENDATIONS,
    payload,
  };
}

export function createLeadRecommendationsSuccess(payload) {
  return {
    type: ACTION_TYPES.CREATE_LEAD_RECOMMENDATIONS_SUCCESS,
    payload,
  };
}

export function createLeadRecommendationsFailure(payload) {
  return {
    type: ACTION_TYPES.CREATE_LEAD_RECOMMENDATIONS_FAILURE,
    payload,
  };
}