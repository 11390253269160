import { ACTION_TYPES } from 'src/constants';

export function requestAdminLogin(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_LOGIN,
    payload,
  };
}

export function adminLoginSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_LOGIN_SUCCESS,
    payload,
  };
}

export function adminLoginFailure(payload) {
  return {
    type: ACTION_TYPES.ADMIN_LOGIN_FAILED,
    payload,
  };
}

export function requestAdminVerifyOtp(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_VERIFY_OTP,
    payload,
  };
}

export function adminVerifyOtpSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_VERIFY_OTP_SUCCESS,
    payload,
  };
}

export function adminVerifyOtpFailure(payload) {
  return {
    type: ACTION_TYPES.ADMIN_VERIFY_OTP_FAILED,
    payload,
  };
}

export function requestAdminLogout(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_LOGOUT,
    payload,
  };
}

export function adminLogoutSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_LOGOUT_SUCCESS,
    payload,
  };
}

export function adminLogoutFailure(payload) {
  return {
    type: ACTION_TYPES.ADMIN_LOGOUT_FAILED,
    payload,
  };
}

export function requestAdminVerifyToken(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_VERIFY_TOKEN,
    payload,
  };
}

export function adminVerifyTokenSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_VERIFY_TOKEN_SUCCESS,
    payload,
  };
}

export function adminVerifyTokenFailure(payload) {
  return {
    type: ACTION_TYPES.ADMIN_VERIFY_TOKEN_FAILED,
    payload,
  };
}

export function requestAdminNewADLogin(payload) {
  return {
    type: ACTION_TYPES.REQUEST_ADMIN_AD_LOGIN,
    payload,
  };
}

export function adminNewADLoginSuccess(payload) {
  return {
    type: ACTION_TYPES.ADMIN_AD_LOGIN_SUCCESS,
    payload,
  };
}

export function adminNewADLoginFailure(payload) {
  return {
    type: ACTION_TYPES.ADMIN_AD_LOGIN_FAILED,
    payload,
  };
}

export function requestAdminSandboxToggle(payload) {
  return {
    type: ACTION_TYPES.ADMIN_SANDBOX_TOGGLE,
    payload,
  };
}

export function requestToggleMobileSideMenu(payload) {
  return {
    type: ACTION_TYPES.TOGGLE_MOBILE_SIDE_MENU,
    payload,
  };
}