import { Dialog, DialogTitle, DialogContent, Avatar, Typography,
  Button, Stepper, Step, StepLabel, StepContent, Stack, Chip,
  DialogActions, IconButton } from '@mui/material'
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import CloseIcon from '@mui/icons-material/Close';
import CreateNotes from '../notes';
import React, { Component } from 'react'
import { Helpers } from "src/helpers";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from '@mui/icons-material/Delete';
import { grey } from '@mui/material/colors';
import _ from 'lodash';
import moment from 'moment'

class AllInteractionsDialog extends Component {
  constructor(props){
    super(props);
    this.state = {
      showCreateInteraction: false
    };
  };

  render() {
    const { show, onClose, lead } = this.props;
    const { showCreateInteraction } = this.state;
    const { leadNotes } = lead
    return (
      <>
        <Dialog
          open={open}
          onClose={onClose}
          fullWidth={true}
          maxWidth="md">
          <DialogTitle>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Avatar sx={{ bgcolor: grey[600] }}>{lead.fullName[0]}</Avatar>
              <span style={{marginLeft: 8}}>{lead.fullName}'s interaction timeline</span>
            </div>
          </DialogTitle>
          <IconButton
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            {_.isEmpty(leadNotes) && 
              <Typography style={{textAlign: 'center'}} variant="subtitle2"> No interactions yet! </Typography>
            }

            <Timeline position="alternate">
              {_.reverse(_.sortBy(leadNotes, 'noteDate')).map((note, index) => {
                const align = index % 2 == 0 ? 'right' : 'left'


                return (<TimelineItem>
                  <TimelineOppositeContent
                    sx={{ m: 'auto 0' }}
                    align={align}
                    variant="body2"
                    color="text.secondary"
                  >
                    {moment(note.noteDate, "DD/MM/YYYY").format("Do MMM, YYYY")}
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot>

                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography variant="subtitle2">{note.note}</Typography>
                    <Stack direction="column" spacing={0.5} style={align == 'left' ? {alignItems: 'flex-end'} : {alignItems: 'flex-start'}}>
                    { _.map(note.keywords, (keyword) => {
                      const color = Helpers.interpolateColorHSV("#ff9800", "#00796b", 0, 10, keyword.ratingOutOfTen)
                      const lightColor = Helpers.interpolateColorHex(color, "#ffffff", 0, 100, 70)
                      return (
                        <Chip label={keyword.keywordText} style={{backgroundColor: color, color: lightColor, maxWidth: '50%'}} />
                      )
                    })}
                    </Stack>
                  </TimelineContent>
                </TimelineItem>)
              })}
            </Timeline>
          </DialogContent>

          <DialogActions>
            <Button style={{margin: 8}} variant="contained" startIcon={<AddIcon />} onClick={() => this.setState({showCreateInteraction: true})}>
              Add Interactions
            </Button>
          </DialogActions>

        </Dialog>

        { showCreateInteraction &&
            <CreateNotes
              isOpen={this.state.showCreateInteraction}
              onClose={() => this.setState({showCreateInteraction: false})}
              lead={lead}
        />
        }
      </>
    );
  }
}

export default AllInteractionsDialog;